export const statisticsOption = {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    top: '5%',
    left: 'center'
  },
  title: {
    text: '80个数',
    left: 'center',
    top: '50%',
    textStyle: {
      color: '#27D9C8',
      fontSize: 22,
      align: 'center'
    }
  },
  graphic: {
    type: 'text',
    left: 'center',
    top: '40%',
    style: {
      text: '发布媒介量',
      textAlign: 'center',
      fill: '#333',
      fontSize: 16,
      fontWeight: 700
    }
  },
  series: [
    {
      color: [
        '#FF8452',
        '#5470C6',
        '#91CC75',
        '#FAC858',
        '#EE6666',
        '#73C0DE',
        '#3BA272'
      ],
      name: '全部媒介',
      type: 'pie',
      radius: ['40%', '60%'],
      avoidLabelOverlap: false,
      label: {
        normal: {
          show: false,
          position: 'center'
        }
      },

      data: [
        { value: 735, name: 'Direct' },
        { value: 580, name: 'Email' },
        { value: 484, name: 'Union Ads' },
        { value: 302, name: 'Vid3eo Ads' },
        { value: 320, name: 'Vide2o Ads' },
        { value: 340, name: 'Vid1eo Ads' }
      ]
    }
  ]
}
