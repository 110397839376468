<template>
  <div class="behaviorBar"></div>
</template>

<script>
export default {
  name: '',
  props: {
    dataArr: Array,
    xdataArr: Array
  },
  data() {
    return {}
  },
  watch: {
    dataArr: {
      // immediate: true,
      // handler(newV, oldV) {
      handler() {
        // this.dataArr = newV
        console.log(this.dataArr, 'this.dataArr')
        this.setChart()
        console.log(this.xdataArr)
      },
      deep: true
    }
    // xdataArr: {
    //   handler(newV, oldV) {
    //     this.setChart()
    //     console.log(newV);

    //   },
    //   deep: true
    // }
  },

  methods: {
    setChart() {
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: '{b}:{c}',
          textStyle: {
            // color: '#6dd0e3',
            fontSize: 8
          }
        },
        grid: {
          top: '30px',
          left: '1%',
          right: '40px',
          bottom: '4%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            fontSize: 12,
            interval: 0,
            rotate: 45,
            showMinLabel: true
          },
          axisLine: {
            lineStyle: {
              // color: '#1a3c58',
              width: 2
            }
          },
          axisTick: { show: false },

          data: this.xdataArr
        },
        yAxis: [{ type: 'value', boundaryGap: [0, '30%'],}],
        series: [
          {
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList = ['#cca272', '#74608f', '#d7a02b', '#c8ba23','#c67a12','#9c0a52','#3c8a92']
                  return colorList[params.dataIndex]
                }
              }
            },
            name: '',
            type: 'bar',
            barWidth: 30,
            barGap: '-100%',
            data: this.dataArr
          }
        ]
      }

      let myChart = this.$echarts(this.$el)

      myChart.clear()
      myChart.resize()
      myChart.setOption(option)
      const viewElem = document.body
      const resizeObserver = new ResizeObserver(() => {
        if (myChart) {
          myChart.resize()
        }
      })
      resizeObserver.observe(viewElem)
    }
  },
  mounted() {
    this.setChart()
  }
}
</script>

<style lang="scss" scoped>
.behaviorBar {
  height: 100%;
}
</style>
