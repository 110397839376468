import apiServer from 'api/apiServer.js'

// 数据统计饼图
export function statisticsPieCharApi(params = {}) {
  let url = `/statistics/channelType/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

// 文章推送数据统计
export function articlePushDataStatisticsApi(params = {}) {
  let url = `/statistics/articlePushDataStatistics/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

// 数据统计列表-运营后台
export function platformPageApi(params = {}) {
  let url = `/statistics/platform/page`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}
// 数据统计列表-运营后台(导出)
export function platformExportApi(params = {}) {
  return apiServer
    .downfileStream('/statistics/platform/page/export', params)
    .then(res => {
      return Promise.resolve(res)
    })
}

// 获取文章推送详情(运营后台)
export function detailsPlatformApi(params = {}) {
  let url = `/article/push/details/platform/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function detailsPlatformApi1(params = {}) {
    let url = `/article/push/details/platform1/${params}`
    return apiServer.get(url).then(res => {
        return Promise.resolve(res)
    })
}

export function detailsPlatformApi2(params = {}) {
  let url = `/article/push/details/platform2/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

// 获取文章推送详情(运营后台)
export function detailsPlatEmailApi(params = {}) {
    let url = `/article/push/details/email/${params}`
    return apiServer.get(url).then(res => {
        return Promise.resolve(res)
    })
}


// 删除模块信息
export function pushDetailsApi(params = {}) {
  let url = `/article/push/details/${params}`
  return apiServer.del(url).then(res => {
    return Promise.resolve(res)
  })
}

// 修改文章推送详情
export function updPushDetailsApi(params = {}) {
  let url = `/article/push/details/updPushDetails`
  return apiServer.put(url, params).then(res => {
    return Promise.resolve(res)
  })
}

//数据详情邮件发送(新)
export function dataDetailsEmailNewApi(params = {}) {
  let url = `/common/send/email/dataDetailsEmailNew/${params}`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}




// export function exportWordDownApi(params = {}) {
//   let url = `/common/send/email/exportWordDownNew/${params}`
//   apiServer.get(url,params)
// }



//根据id9获取文章标题
export function getTitleByIdApi(params = {}) {
  let url = `/common/send/email/getTitleById/${params}`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}
export function getReleaseDataApi(params = {}) {
  let url = `/statistics/getReleaseData`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}
export function getChannelDataApi(params = {}) {
  let url = `/statistics/getChannelData`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}
//根据公司名称获取行业图表数据
export function getIndustryInfoByStockNameApi(params = {}) {
  let url = `/statistics/getIndustryInfoByStockName/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}
export function getBureauInfoByStockNameApi(params = {}) {
  let url = `/statistics/getBureauInfoByStockName/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}
export function getIndustryRankingByStockNameApi(params = {}) {
  let url = `/statistics/getIndustryRankingByStockName/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function getBureauRankingByStockNameApi(params = {}) {
  let url = `/statistics/getBureauRankingByStockName/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}
export function getIndustryInfoApi(params = {}) {
  let url = `/statistics/getIndustryInfo/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function getIndustryRankingApi(params = {}) {
  let url = `/statistics/getIndustryRanking/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function getBureauInfoApi(params = {}) {
  let url = `/statistics/getBureauInfo/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function getBureauRankingApi(params = {}) {
  let url = `/statistics/getBureauRanking/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}


//当天同行业信息
export function getIndustryInfoDayApi(params = {}) {
  let url = `/statistics/getIndustryArticleInfoDay/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

//区间内发布过的文章
export function getReleaseArticleApi(params = {}) {
  let url = `/statistics/getReleaseArticle`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function exportExcelDownApi(params = {}) {
  return apiServer
    .downfileStream(`/common/send/email/exportExcelDown/${params}`)
    .then((res) => {
      return Promise.resolve(res);
    });
}


export function exportWordDownApi(params = {}) {
  return apiServer
    .downfileStream(`/common/send/email/exportWordDownNew/${params}`)
    .then((res) => {
      return Promise.resolve(res);
    });
}

// 数据详情邮件发送
export function dataDetailsEmailApi(params = {}) {
  let url = `/common/send/email/dataDetailsEmail`
  return apiServer.upLoadFileStream(url, params).then(res => {
    return Promise.resolve(res)
  })
}
//修改阅读数量
export function editReadNumApi(params = {}) {
  let url = `/article/editReadNum`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

//显示 单条
export function displayApi(params = {}) {
  let url = `/article/push/details/display/${params}`
  return apiServer.put(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 屏蔽
export function hideApi(params = {}) {
  let url = `/article/push/details/hide/${params}`
  return apiServer.put(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// export function delApi(params = {}) {
//   let url = `/article/push/details/${params}`
//   return apiServer.delete(url, params).del(res => {
//     return Promise.resolve(res)
//   })
// }
//删除
export function delApi(params) {
  return apiServer.del(`/article/push/details/${params}`).then(res => {
    return Promise.resolve(res)
  })
}

// 显示：/article/push/details/display/{pushDetailsId}   put
// 屏蔽：/article/push/details/hide/{pushDetailsId}   put
// 删除：/article/push/details/{pushDetailsId}   Delete

// 数据统计列表-运营后台
export function platformPagesApi(params = {}) {
  let url = `/statistics/platform/page`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function queryNoPushListApi(params = {}) {
  let url = `/operate/channel/relevance/queryNoPushList/${params}`
  return apiServer.get(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function queryPushApiAllApi(params = {}) {
  let url = `/operate/channel/relevance/queryPushApiAll`
  return apiServer.get(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function noPushRelevanceApi(params = {}) {
  let url = `/operate/channel/relevance/noPushRelevance`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}