import apiServer from './apiServer'

// 查询字典
// module_type_enum:模块类型
// role_type_enum:角色类型
export function lookDic(params = '') {
  return apiServer.get(`/basic/dictionary/${params}`).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 员工管理
 */
// 员工管理列表
export function getEmployListApi(params = {}) {
  return apiServer.get('/vendor/employ/getEmployList', params).then(res => {
    return Promise.resolve(res)
  })
}
// 设为管理员
export function postEditAdminRoleApi(params = '') {
  return apiServer.post('/vendor/employ/editAdminRole', params).then(res => {
    return Promise.resolve(res)
  })
}
// 移除员工
export function delRemoveEmployApi(params = '') {
  return apiServer.del('/vendor/employ/removeEmploy', params).then(res => {
    return Promise.resolve(res)
  })
}

//URL转base64
export function urlToBase64Api(params = '') {
  return apiServer.get('/common/basic/urlToBase64', params).then(res => {
    return Promise.resolve(res)
  })
} 

// 行业种类树
export function industryCategoryTreeApi(params = '') {
  return apiServer.get('/common/basic/industryCategoryTree', params).then(res => {
    return Promise.resolve(res)
  })
}
