var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "release_detail" }, [
    _c("h2", [
      _vm._v(_vm._s(_vm.detail.articleTitle + " ") + " "),
      _c("sub", { staticStyle: { "font-size": "10px" } }, [
        _vm._v(" 来源： " + _vm._s(_vm.detail.stockName) + " "),
      ]),
    ]),
    _c("h4", { staticClass: "time" }, [
      _vm._v(
        " 时间： " +
          _vm._s(new Date(_vm.detail.createdTime).toLocaleString()) +
          " "
      ),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("div", { domProps: { innerHTML: _vm._s(_vm.detail.articleContent) } }),
    ]),
    _c("h4"),
    _vm.showFalse
      ? _c(
          "div",
          {
            staticStyle: { color: "blue" },
            attrs: { align: "center" },
            on: { click: _vm.aaaa },
          },
          [_vm._v("原文点击跳转")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }