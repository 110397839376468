export const chartsNum = {
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    type: 'value'
  },
  grid: {
    top: '50px',
    left: '40px',
    right: '0px',
    bottom: '40px'
  },
  series: [
    {
      data: [120, 200, 150, 80, 70, 110, 130],
      type: 'bar'
    }
  ]
}

export const expDate = {
  title: [], //
  legend: {},
  tooltip: {
    trigger: 'axis',
    formatter: function (params) {
      params = params[0]
      var date = new Date(params.name)
      var hour = date.getHours()
      var minutes = date.getMinutes()
      if (hour < 10) {
        hour = '0' + hour
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      var dateStr = hour + ':' + minutes
      console.log(dateStr, 'dateStrdateStr')
      return dateStr + ' ' + params.value[1]
    },
    axisPointer: {
      animation: false
    }
  },
  xAxis: {
    type: 'time',
    splitLine: {
      show: false
    },
    splitNumber: 8,
    axisLine: {
      lineStyle: {
        color: '#1B2232'
      }
    },
    axisLabel: {
      formatter: function (value, index) {
        var date = new Date(value)
        var hour = date.getHours()
        var minutes = date.getMinutes()
        if (hour < 10) {
          hour = '0' + hour
        }
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        return hour + ':' + minutes
      },
      color: '#1B2232'
    }
  },
  yAxis: {
    name: '数据',
    nameTextStyle: {
      color: '#1B2232',
      fontSize: 15
    },
    type: 'value',
    boundaryGap: [0, '100%'],
    splitLine: {
      show: false
    },
    axisLine: {
      lineStyle: {
        color: '#1B2232'
      }
    },
    axisLabel: {
      color: '#1B2232'
    }
  },
  series: [
    {
      name: '',
      type: 'line',
      hoverAnimation: false,
      smooth: true,
      symbolSize: 4,
      data: [120, 200, 150, 80, 70, 110, 130]
    }
  ]
}

//仪表盘
export const rateDate = {
  title: [{
    //标题
    text: '',
    left: 'center',
    top: '70%',
    textStyle: {
      //标题样式
      color: '#666',
      fontSize: 16,
      fontWeight: 600,
    }
  }],

  tooltip: {
    formatter: '{b}{c}'
  },
  series: [
    {
      name: '',
      color: "#0096eb",
      type: 'gauge',
      progress: {
        show: true,
        width: 10
      },
      radius: '85%',
      min: 0,
      max: 1,
      center: ['50%', '50%'],
      data: [
        {
          value: 0.29,
          name: ''
        }
      ],
      splitNumber: 3, //刻度数量
      startAngle: 220,
      endAngle: -40,
      z: 5,
      // axisLine: {
      //   // show: true,
      //   // lineStyle: {
      //   //   width: 0,
      //   //   color: [
      //   //     // [0.12, '#E71A6D'],
      //   //     // [0.35, '#F88168'],
      //   //     // [0.63, '#FBF76B'],
      //   //     // [0.8, '#7AD4DF'],
      //   //     // [1, '#70C27E']
      //   //     [0.12, '#000'],
      //   //     [0.35, '#000'],
      //   //     [0.63, '#000'],
      //   //     [0.8, '#000'],
      //   //     [1, '#000']
      //   //   ]
      //   // }
      // }, //仪表盘轴线
      axisLabel: {
        show: true,
        color: '#0096eb',
        fontSize: 16,
        distance: 30,
        formatter: function (params) {
          var value = params.toFixed(2)
          console.log(value);
          if (0.25 > value > 0) {
            return '差'
          } else if (0.5 > value > 0.25) {
            return '中'
          } else if (0.75 > value > 0.5) {
            return '良'
          } else if (1 > value > 0.75) {
            return '优'
          } else {
            return '优'
          }
        }
      }, //刻度标签。
      axisTick: {
        splitNumber: 10,
        show: false,
        lineStyle: {
          color: '0096eb',
          width: 2
        },
        length: 20
      }, //刻度样式
      splitLine: {
        show: true,
        length: 15,
        distance: 1,
        lineStyle: {
          // color: 'auto',
          color: "#0096eb",
          width: 2
        }
      }, //分隔线样式

      itemStyle: {
        normal: {
          color: '#0096eb' //指针颜色
        }
      },
      // pointer: {
      //   width: 4,
      //   length: '40%',
      //   showAbove: false,
      //   offsetCenter: ['0px', '0px']
      // },
      detail: {
        name: '发布率',
        formatter: function (params) {
          return (params * 100).toFixed(0) + '%'
        },
        fontSize: 24,
        color: '#000',
        offsetCenter: ['0%', '86%']
      }
      // title: {
      //   offsetCenter: ['0', '-60%'],
      //   fontSize: 20,
      //   color: '#fff',
      //   show: true
      // }
    }
  ]
}
//发布24h媒介数 折线图
export const expectFun = function () {
  let opt = {
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      splitLine: {
        lineStyle: {
          color: '#29457e'
        }
      },
      axisLabel: {
        interval: 0
      },
      data: ['0:00', '6:00', '12:00', '18:00', '24:00']
    },
    yAxis: [
      {
        type: 'value',
        axisTick: {
          //y轴刻度线
          show: false
        },
        // name: '发布24h媒介数',
        min: 0,
        max: 100,
        interval: 20,
        axisLabel: {
          formatter: '{value}'
        }
      }
    ],
    series: [
      {
        // name: '温度',
        // type: 'line',
        itemStyle: {
          normal: {
            lineStyle: {
              color: '#b1de6a'
            }
          }
        },
        data: [10, 26, 20, 20, 21, 50],
        smooth: true,
        areaStyle: {
          // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: 'red' // 0% 处的颜色
              },
              {
                offset: 1,
                color: 'blue' // 100% 处的颜色
              }
            ],
            global: false // 缺省为 false
          }
        }
      }
    ]
  }
  return opt
}

const formatDate = (date) => {
  let month = '' + (date.getMonth() + 1),
    day = '' + date.getDate(),
    year = date.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
};

//发布24h媒介数 折线图
export const expectFun1 = function () {
  const today = new Date();

  let opt = {
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      top: '10px',
      left: '40px',
      right: '20px',
      bottom: '40px'
    },
    xAxis: {
      type: 'category',
      boundaryGap:false,
      splitLine: {
        lineStyle: {
          color: '#409EFF'
        }
      },
      axisLabel: {
        interval: 'auto'
      },
      data: ['2023-01-01',formatDate(today)]
    },
    yAxis: [
      {
        type: 'value',
        axisTick: {
          //y轴刻度线
          show: false
        },
        name: '',
        min: 0,
        max: 50,
        interval: 10,
        axisLabel: {
          formatter: '{value}'
        }
      }
    ],
    series: [
      {
        name: '总分发数',
        type: 'line',
        itemStyle: {
          normal: {
            lineStyle: {
              color: '#409EFF'
            }
          }
        },
        data: [2.0, 4.9, 7.0, 23.2, 25.6, 23, 25, 21, 26, 20, 25, 36, 48],
        areaStyle: {color: '#409EFF'}
      },

      // {
      //   name: '湿度',
      //   type: 'line',
      //   itemStyle: {
      //     normal: {
      //       lineStyle: {
      //         color: '#4ab0ee'
      //       }
      //     }
      //   },
      //   data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 75.6, 82.2, 54, 63]
      // }
    ]
  }
  return opt
}
