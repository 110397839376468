<template>
  <div class="data_statistics">
    <div class="channelManagement-head">
      <el-button type="primary" @click="$router.push('/home')"
      >返回</el-button
      >
    </div>

    <div class="statistics_detail">
      <el-row
        style="
          display: flex;
          align-items: center;
          border-bottom: 1px solid #ddd;
        "
      >
        <el-col :span="12"
        ><h4 style="font-size: 25px; margin: 20px 0">
          {{ articleParams.articleTitle }}
        </h4></el-col
        >
        <el-col :span="12" class="text-right">
          <el-button type="primary" @click="exportReportClick"
          >下载报告</el-button>
        </el-col>
      </el-row>

      <div class="statistics_detail">
        <div class="statistics_top">
          <p style="color: #9e9e9e">
            分发至今 {{ articleParams.createdTime }} - {{ articleParams.nowTime }}
          </p>
          <div class="data_top_wrap">
            <div class="data_top_item">
              <h3>{{ topParams.pushApiNum }}</h3>
              <h3>分发媒介数</h3>
            </div>
            <div class="data_top_item">
              <h3>{{ topParams.channelTypeNum }}</h3>
              <h3>媒介类型</h3>
            </div>
            <div class="data_top_item">
              <h3 v-if="!isEditReadNum">{{ topParams.readNum }}</h3>
              <h3>
                阅读量<i
                v-if="userbtn.indexOf('DATASTATISITICE_EDIT_READNUM') >= 0"
              ></i>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div id='content'>

        <div>
          <div class="scope_row_wrap">
            <div class="chartDataWarp">
              <div
                class="echarts"
                :id="detailId"
                style="width: 300px; height: 300px"
              ></div>
              <!-- <chart-box :ref="'expectChart'" id="rowsa" /> -->
            </div>
            <div class="chart-list">
              <el-row>
                <el-col>
                  <div
                    class="chart-list-item"
                    :class="'list-item' + index"
                    v-for="(d, index) in scopeDetail"
                    :key="d.id"
                  >
                    <h5><i class="circle-item"></i> {{ d.channelName }}</h5>
                    <p v-for="(item, ind) in d.list" :key="item.id" style='display: inline-block'>
                      <span  style='margin-right: 20px'>
                        <span @click="openUrl(item.urlLink)">{{
                            item.channelName
                          }}</span>
                      </span>
                    </p>
                    <!--                    <p v-if="d.length > 5">......</p>-->
                  </div>
                </el-col>
              </el-row>

            </div>
          </div>

        </div>
        <div>
          <el-row>
            <el-col :span='12'>
              <p style='text-align: left'>同业分发排名:</p>
              <div id="industry" style="width: 500px; height: 450px;"></div>
              <p style='text-align: left'>
                您公司当前在同行业中分发数量排名为第<span style='color:red'>{{rankingNumber}}</span>名!
              </p>
            </el-col>

            <el-col :span='12'>
              <p style='text-align: left'>同辖区分发排名:</p>
              <div id="bureau" style="width: 500px; height: 450px;"></div>
              <p style='text-align: left'>
                您公司当前在同辖区中分发数量排名为第<span style='color:red'>{{bureauRankingNumber}}</span>名!
              </p>
            </el-col>

          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
import {
  articlePushDataStatisticsApi,
  detailsPlatformApi2,
  detailsPlatformApi,
  getIndustryInfoApi,
  getIndustryRankingApi,
  getBureauInfoApi,
  getBureauRankingApi,
  exportWordDownApi,
  statisticsPieCharApi
} from 'api/statistics'
import { articlecontentApi } from 'api/article'
import { statisticsOption } from 'util/js/statisticsEcharts'
export default {
  props: {
    dataId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      scopeDetail: [],
      bureauRankingNumber:0,
      rankingNumber:0,
      isEditReadNum: false,
      readNumEdit: null,
      tableLoading: false,
      topParams: {
        pushApiNum: 0, //api媒介数量
        channelTypeNum: 0, //媒介类型名称
        readNum: 0 //阅读量
      },
      currentItem: {}, //当前编辑item
      detailParams: [], // 推送列表
      detailParams1: [], // 推送列表
      detailParams2: [], // 推送列表
      articleParams: {}, //文章详情
      industryOption:[],
      bureauOption:[],
      articleParams1: {
      }, //文章详情
      detailId: 0,
      tableData:[]
    }
  },
  created() {
    // 页面加载后立即滚动到顶部
    window.scrollTo(0, 0);
    this.detailId = this.$route.query.id || this.dataId
    console.log(this.detailId)
    this.initTopData()
    this.initDetail()
    this.industryEcharts()
    this.BureauEcharts();
    this.getIndustryRanking()
    this.getBureauRanking();
    this.clickRowHandle(this.detailId);
  },
  mounted() {

  },
  methods: {
    exportReportClick(){
      //下载分发报告
      exportWordDownApi(this.detailId).then( data => {
        let blob = new Blob([data.data])
// 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        let dateXls = this.articleParams.articleTitle;
        link.download = this.articleParams.stockName+"-"+`${dateXls}"分发报告.pdf`
        link.href = blobUrl
        link.click()
        link.remove()
// 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
      })
    },
    getBureauRanking(){
      getBureauRankingApi(this.detailId).then(res => {
        if (res.code === 200){
          this.bureauRankingNumber = res.result;
        }
      })
    },
    getIndustryRanking(){
      getIndustryRankingApi(this.detailId).then(res => {
        if (res.code === 200){
          this.rankingNumber = res.result;
        }
      })
    },
    BureauEcharts(){
      getBureauInfoApi(this.detailId).then(res => {
        if (res.code === 200){
          this.bureauOption = res.result;
          const stockNameArr = this.bureauOption.map(item => item.stockName);
          const validSumArr = this.bureauOption.map(item => item.validSum)
          var bureauOption = {
            tooltip: {
            },
            xAxis: {
              type: 'category',
              data: stockNameArr,
              axisLabel: {
                fontSize: 12,
                interval: 0,
                rotate: 45
              }
            },
            axisLabel: {
              fontSize: 12,
              interval: 0,
              rotate: 45
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                // validSumArr,
                data: [
                  {
                    value: validSumArr[0],
                    itemStyle: {
                      color: 'rgba(65,105,225)'
                    }
                  },
                  {
                    value: validSumArr[1],
                    itemStyle: {
                      color: 'rgba(50,205,50)'
                    }
                  },
                  {
                    value: validSumArr[2],
                    itemStyle: {
                      color: 'rgba(255,215,0)'
                    }
                  },
                  {
                    value: validSumArr[3],
                    itemStyle: {
                      color: 'rgba(0,128,0)'
                    }
                  },
                  {
                    value: validSumArr[4],
                    itemStyle: {
                      color: 'rgba(70,130,180)'
                    }
                  },
                  {
                    value: validSumArr[5],
                    itemStyle: {
                      color: 'rgba(0,255,255)'
                    }
                  },
                  {
                    value: validSumArr[6],
                    itemStyle: {
                      color: 'rgba(255,140,0)'
                    }
                  },
                  {
                    value: validSumArr[7],
                    itemStyle: {
                      color: 'rgba(255,0,0)'
                    }
                  },
                  {
                    value: validSumArr[8],
                    itemStyle: {
                      color: 'rgba(147,112,219)'
                    }
                  },
                  {
                    value: validSumArr[9],
                    itemStyle: {
                      color: 'rgba(0,255,0)'
                    }
                  },
                ],
                type: 'bar'
              }
            ]
          };
          var chartDom = document.getElementById('bureau');
          var myChart = echarts.init(chartDom);
          myChart.setOption(bureauOption);
        }
      })
    },
    industryEcharts(){
      getIndustryInfoApi(this.detailId).then(res => {
        if (res.code === 200){
          this.industryOption = res.result;
          const stockNameArr = this.industryOption.map(item => item.stockName);
          const validSumArr = this.industryOption.map(item => item.validSum)
          var industryOption = {
            tooltip: {},
            xAxis: {
              type: 'category',
              data: stockNameArr,
              axisLabel: {
                fontSize: 12,
                interval: 0,
                rotate: 45
              }
            },
            axisLabel: {
              fontSize: 12,
              interval: 0,
              rotate: 45
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                // validSumArr,
                data: [
                  {
                    value: validSumArr[0],
                    itemStyle: {
                      color: 'rgba(65,105,225)'
                    }
                  },
                  {
                    value: validSumArr[1],
                    itemStyle: {
                      color: 'rgba(50,205,50)'
                    }
                  },
                  {
                    value: validSumArr[2],
                    itemStyle: {
                      color: 'rgba(255,215,0)'
                    }
                  },
                  {
                    value: validSumArr[3],
                    itemStyle: {
                      color: 'rgba(0,128,0)'
                    }
                  },
                  {
                    value: validSumArr[4],
                    itemStyle: {
                      color: 'rgba(70,130,180)'
                    }
                  },
                  {
                    value: validSumArr[5],
                    itemStyle: {
                      color: 'rgba(0,255,255)'
                    }
                  },
                  {
                    value: validSumArr[6],
                    itemStyle: {
                      color: 'rgba(255,140,0)'
                    }
                  },
                  {
                    value: validSumArr[7],
                    itemStyle: {
                      color: 'rgba(255,0,0)'
                    }
                  },
                  {
                    value: validSumArr[8],
                    itemStyle: {
                      color: 'rgba(147,112,219)'
                    }
                  },
                  {
                    value: validSumArr[9],
                    itemStyle: {
                      color: 'rgba(0,255,0)'
                    }
                  },
                ],
                type: 'bar'
              }
            ]
          };
          var chartDom = document.getElementById('industry');
          var myChart = echarts.init(chartDom);
          myChart.setOption(industryOption);
        }
      })
    },
    //初始化推送列表
    initDetail() {
      this.tableLoading = true
      // detailsPlatformApi1(this.detailId).then(res => {
      detailsPlatformApi2(this.detailId).then(res => {
        this.tableLoading = false
        this.pushList = res.result
        this.pushList.map(res => {
          res.detailParams.isEdit = false
          res.detailParams.createdTime = new Date(res.detailParams.createdTime).toLocaleString()
          if (res.detailParams.monthlyActivity) {
            let str  = res.detailParams.monthlyActivity.toString()
            if (str.length > 4) {
              res.detailParams.numberCount = str.substring(0,str.length - 4)+"(万)";
            }
          } else {
            res.detailParams.numberCount = "/"
          }

          if (res.detailParams.remarks) {
            res.detailParams.redBook = res.detailParams.remarks;
          } else {
            res.detailParams.redBook = "/"
          }
          console.log('res',res)
          if (res.detailParams.urlLinkType === 10) {
            res.detailParams.hasText = true;
            res.detailParams.hasHtml = '<a href="' + res.detailParams.urlLink + '">' + '查看链接' + '</a>';
            res.detailParams.has = false;
          } else if (res.detailParams.urlLinkType === 20) {
            res.detailParams.hasHtml = '<a href="' + res.detailParams.urlLink + '">' + '查看截图' + '</a>';
          } else {
            res.detailParams.hasHtml ="/"
          }

        });

      })
      detailsPlatformApi(this.detailId).then(res => {
        this.detailParams1 = res.result
        console.log('  this.detailParams1',  this.detailParams1)

        const data = this.detailParams1.map(item => ({
          value: item.length,
          name: item.channelName,
        }));
        var option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          series: [
            {
              name: '分发媒介详情',
              type: 'pie',
              radius: ['30%', '50%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 40,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data:data
            }
          ]
        };

        var chartDom = document.getElementById('main');
        var myChart = echarts.init(chartDom);
        myChart.setOption(option);
        this.detailParams1.map(res => {
          for (let i = 0; i <res.list.length; i++) {
            res.list[i].isEdit = false
            res.list[i].createdTime = new Date(res.createdTime).toLocaleString()

            if ( res.list[i].monthlyActivity) {
              let str  =  res.list[i].monthlyActivity.toString()
              if (str.length > 4) {
                res.list[i].numberCount = str.substring(0, str.length - 4) + "(万)";
              } else {
                res.list[i].numberCount ='1(万)'
              }
            } else {
              res.list[i].numberCount = "/"
            }

            if ( res.list[i].remarks) {
              res.list[i].redBook =  res.list[i].remarks;
            } else {
              res.list[i].redBook = "/"
            }


            if ( res.list[i].urlLinkType === 10) {
              res.list[i].hasText = true;
              res.list[i].hasHtml = '<a href="' +  res.list[i].urlLink + '">' + '查看链接' + '</a>';
              res.list[i].has = false;

            } else if ( res.list[i].urlLinkType === 20) {
              res.list[i].hasHtml = '<a href="' +  res.list[i].urlLink + '">' + '查看截图' + '</a>';
            } else {
              res.list[i].hasHtml ="/"
            }
          }
        });
      })
      articlecontentApi(this.detailId).then(res => {
        this.articleParams = { ...res.result }
        this.articleParams.nowTime = new Date().toLocaleDateString()
        this.articleParams.createdTime = new Date(
          this.articleParams.createdTime
        ).toLocaleDateString()

      })
    },
    clickRowHandle(id) {
      console.log("id:"+id)
      let chartsBool = true
      this.$nextTick(() => {
        statisticsPieCharApi(id).then(res => {

          this.scopeDetail = res.result


          let num = 0;

          this.scopeDetail.forEach(item => {

            num +=item.length;

          })
          let arr = []

          this.scopeDetail.map((item, index) => {
            arr.push({
              value: item.length,
              name: item.channelName
            })
          })
          statisticsOption.series[0].data = arr
          statisticsOption.title.text = num
          if (chartsBool) {
            this.initChart(id)
          }
        })
      })
    },
    openUrl(url) {
      if (url) {
        if (url.includes('http') || url.includes('http')) {
          window.open(url, '_blank')
        } else {
          this.$message.warning(`请配置合法跳转路由！！`)
        }
      }
    },
    initChart(id) {
      console.log("id:"+id)
      let mychart = echarts.init(document.getElementById(id))
      mychart.setOption(statisticsOption)
      this.img2 =  mychart.getDataURL({
        type: 'jpg',
        pixelRatio: 1,
        backgroundColor:'white'
      })

    },
    //初始化文章信息
    initTopData() {
      articlePushDataStatisticsApi(this.detailId).then(res => {
        this.topParams = { ...res.result }
      })
    },
  },
}
</script>



<style lang="scss" scoped>
.data_statistics {
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
    margin-bottom: 20px;
  }
  .statistics_detail {
    background: #fff;
    width: 95%;
    border-radius: 3px;
    padding: 20px;
    padding-top: 10px;
    margin: 0 auto;
    .data_top_wrap {
      // width: 95%;
      // margin: 0 auto;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      .data_top_item {
        width: 33%;
        text-align: center;
        font-size: 20px;
        border-right: 1px solid #ddd;
        padding: 10px 0;
        h3 {
          margin: 5px 0;
        }
        &:nth-child(1) {
          color: #f59a23;
        }
        &:nth-child(2) {
          color: #7cbfae;
        }
        &:nth-child(3) {
          color: #8080ff;
          border: none;
        }
      }
    }
    /deep/.el-upload {
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #999;
    }
    /deep/.avatar-uploader {
      width: 80px;
      height: 80px;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .scope_row_wrap {
      display: flex;
      justify-content: space-around;
      .chartDataWarp {
        width: 300px;
        height: 300px;
      }
      .chart-list {
        display: flex;
        flex-wrap: wrap;
        width: 500px;
        .chart-list-item {
          margin-right: 20px;
          span {
            cursor: pointer;
            &:hover {
              color: #0096eb !important;
            }
          }
          a {
            text-decoration: none;
            color: #606266;
          }
        }
        .circle-item {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: inline-block;
        }
        .list-item0 {
          h5 {
            color: #ff8452;
          }
          .circle-item {
            background: #ff8452;
          }
        }
        .list-item0 {
          h5 {
            color: #ff8452;
          }
          .circle-item {
            background: #ff8452;
          }
        }
        .list-item1 {
          h5 {
            color: #5470c6;
          }
          .circle-item {
            background: #5470c6;
          }
        }
        .list-item2 {
          h5 {
            color: #91cc75;
          }
          .circle-item {
            background: #91cc75;
          }
        }
        .list-item3 {
          h5 {
            color: #fac858;
          }
          .circle-item {
            background: #fac858;
          }
        }
        .list-item4 {
          h5 {
            color: #ee6666;
          }
          .circle-item {
            background: #ee6666;
          }
        }
        .list-item5 {
          h5 {
            color: #73c0de;
          }
          .circle-item {
            background: #73c0de;
          }
        }
      }
    }
  }
}

.select_warn {
  background: rgb(230, 247, 255);
  padding: 10px;
  border-radius: 10px;
  span {
    color: $primary;
  }
}
</style>
