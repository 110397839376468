<template>
  <div class="data_statistics">
    <div class="channelManagement-head">
      <el-button type="primary" @click="$router.push('/dataStatistics')"
        >返回</el-button
      >
    </div>
    <div class="statistics_detail">
      <el-row
        style="
          display: flex;
          align-items: center;
          border-bottom: 1px solid #ddd;
        "
      >
        <el-col :span="12"
          ><h4 style="font-size: 25px; margin: 20px 0">
            {{ articleParams.articleTitle }}
          </h4></el-col
        >
        <el-col :span="12" class="text-right">
          <el-button
            type="primary"
            size="small"
            @click="toEmail"
            v-if="userbtn.indexOf('DATASTATISITICE_MAIL_EXPORT') >= 0"
            >邮件通知</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="exportWordDownClick"
            v-if="userbtn.indexOf('DATASTATISITICE_DETAIL_EXPORT') >= 0"
            >导出报告</el-button
          >


          <el-button
            type="primary"
            size="small"
            @click="exportExcelDownClick"
            v-if="userbtn.indexOf('DATASTATISITICE_DETAIL_EXPORT') >= 0"
          >导出表格</el-button
          >

        </el-col>
      </el-row>
      <div class="statistics_top">
        <p style="color: #9e9e9e">
          分发至今 {{ articleParams.createdTime }} - {{ articleParams.nowTime }}
        </p>
        <div class="data_top_wrap">
          <div class="data_top_item">
            <h3>{{ topParams.pushApiNum }}</h3>
            <h3>分发媒介数</h3>
          </div>
          <div class="data_top_item">
            <h3>{{ topParams.channelTypeNum }}</h3>
            <h3>媒介类型</h3>
          </div>
          <div class="data_top_item">
            <h3 v-if="!isEditReadNum">{{ topParams.readNum }}</h3>
            <div v-if="isEditReadNum">
              <el-input-number v-model="readNumEdit"></el-input-number>
              <el-button type="text" @click="editReadNum(true)">保存</el-button>
              <el-button type="text" @click="editReadNum(false)"
                >取消</el-button
              >
            </div>
            <h3>
              阅读量<i
                v-if="userbtn.indexOf('DATASTATISITICE_EDIT_READNUM') >= 0"
              ></i>
            </h3>
          </div>
        </div>
      </div>
      <!-- <h4 style="border-bottom:1px solid #ddd;padding-bottom:20px;">推送媒介分布</h4> -->
      <div class="statistics_content" v-for='item in pushList'>
        <el-table
          :header-cell-style="{background:'#B0C4DE',color:'#606266'}"
          :data="item.detailParams"
          style="width: 100%"
        >
<!--          //媒介名称-->

          <el-table-column  prop="channelName" :label=item.brokerName>
          </el-table-column>
          <el-table-column
            prop="createdTime"
            :formatter="formatData"
            label="发布时间"
          >
            <!--  -->
          </el-table-column>
          <!-- <el-table-column prop="giveNum" label="点赞量">
            <template slot-scope="{ row }">
              <div class="hidd" v-if="!row.isEdit">
                {{ row.giveNum }}
              </div>
              <div class="hidd" v-else>
                <el-input v-model="currentItem.giveNum"></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="readNum" label="阅读量">
            <template slot-scope="{ row }">
              <div class="hidd" v-if="!row.isEdit">
                {{ row.readNum }}
              </div>
              <div class="hidd" v-else>
                <el-input v-model="currentItem.readNum"></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionNum" label="收藏量">
            <template slot-scope="{ row }">
              <div class="hidd" v-if="!row.isEdit">
                {{ row.collectionNum }}
              </div>
              <div class="hidd" v-else>
                <el-input v-model="currentItem.collectionNum"></el-input>
              </div>
            </template>
          </el-table-column> -->
          <el-table-column prop="urlLink" label="链接/截图">
            <template slot-scope="{ row }">
              <div class="hidd" v-if="!row.isEdit">
                <span v-if="row.urlLinkType == 10">
                  {{ row.urlLink }}
                </span>
                <img
                  :src="row.urlLink"
                  v-if="row.urlLinkType == 20"
                  alt=""
                  width="80px"
                  height="80px"
                />
              </div>
              <div class="hidd" v-else>
                <el-select v-model="currentItem.urlLinkType">
                  <el-option label="链接" value="10"></el-option>
                  <el-option label="图片" value="20"></el-option>
                </el-select>
                <el-input
                  v-if="currentItem.urlLinkType == 10"
                  v-model="currentItem.urlLink"
                ></el-input>
                <el-upload
                  class="avatar-uploader"
                  :action="uploadUrl"
                  :headers="myHeaders"
                  accept="image/jpeg,image/jpg,image/png"
                  :show-file-list="false"
                  :on-success="uploadReturn"
                  :before-upload="beforeAvatarUpload"
                  :on-change="uploadChange"
                  :on-exceed="handleExceed"
                  v-if="currentItem.urlLinkType == 20"
                  :on-remove="companyUploadRemove"
                >
                  <img
                    v-if="currentItem.urlLink"
                    :src="currentItem.urlLink"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <!-- <el-upload
                  v-if="currentItem.urlLinkType == 20"
                  list-type="picture-card"
                  :limit="1"
                  :file-list="fileList"
                >
                  <i class="el-icon-plus"></i>
                </el-upload> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <template class="hidd" v-if="!row.isEdit">
                <el-button
                  type="text"
                  @click="editItem(row)"
                  v-if="userbtn.indexOf('DATASTATISITICE_ITEM_EDIT') >= 0"
                  >编辑</el-button
                >
                <!-- <el-button
                  type="text"
                  @click="hideShowBtn(row)"
                  v-if="userbtn.indexOf('DATASTATISITICE_ITEM_HIDE') >= 0"
                  ><span v-if="row.deleteFlag">显示</span><span v-if="!row.deleteFlag">屏蔽</span></el-button> -->
                <el-button
                  type="text"
                  @click="delItem(row)"
                  v-if="
                    userbtn.indexOf('DATASTATISITICE_ITEM_HIDE') >= 0 &&
                    !row.deleteFlag
                  "
                  >屏蔽</el-button
                >
                <el-button
                  type="text"
                  @click="hideShowBtn(row)"
                  v-if="
                    userbtn.indexOf('DATASTATISITICE_ITEM_SHOW') >= 0 &&
                    row.deleteFlag
                  "
                  >显示</el-button
                >
                <el-button
                  v-if="userbtn.indexOf('DATASTATISITICE_ITEM_DEL') >= 0"
                  type="text"
                  @click="delItemmth(row)"
                  >删除</el-button
                >
              </template>
              <template class="hidd" v-else>
                <el-button
                  type="text"
                  @click="preserItem(row)"
                  v-if="userbtn.indexOf('DATASTATISITICE_ITEM_SAVE') >= 0"
                  >保存</el-button
                >
                <el-button
                  type="text"
                  @click="cancelItem(row)"
                  v-if="userbtn.indexOf('DATASTATISITICE_ITEM_CANCEL') >= 0"
                  >取消</el-button
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <div v-for="item in detailParams" :key="item.id"></div> -->
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="邮件通知"
      :visible.sync="dialogAdd"
    >
      <el-form
        :model="mailForm"
        :rules="mailRules"
        ref="mailRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="发件人:" prop="name">
          <el-input v-model="mailForm.defaultName" :disabled="true"></el-input>
        </el-form-item>
      <!--  <p>最多添加5个邮件地址，中间用英文分号(:)进行分隔</p>-->
        <el-form-item label="收件人邮箱:" prop="toEmailIpt">
          <el-input v-model="mailForm.toEmailIpt" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="邮件标题" prop="customSubject">
          <el-input v-model="mailForm.customSubject"></el-input> </el-form-item
      ></el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogAdd = false">取 消</el-button>
        <el-button type="primary" @click="addFriend">确 定</el-button>
      </div>
    </el-dialog>
    <vue-html2pdf
      :show-layout="showLayout"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="6000"
      filename="数据统计"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a3"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-orientation="landscape"
      pdf-content-width="1000px"
      ref="html2Pdf"
      @hasDownloaded="hasDownloadedPdf"
    >
      <section slot="pdf-content">
        <div class="data_statistics">
          <!-- <div class="channelManagement-head">
            <el-button type="primary" @click="$router.push('/dataStatistics')"
              >返回</el-button
            >
          </div> -->
          <div class="statistics_detail">
            <img src="@/assets/images/1.jpg" alt="" />
            <el-row
              style="
                display: flex;
                align-items: center;
                border-bottom: 1px solid #ddd;
              "
            >
              <el-col :span="12"
                ><h4>{{ articleParams.articleTitle }}</h4></el-col
              >
              <!-- <el-col :span="12" class="text-right">
                <el-button type="primary" size="small" @click="dialogAdd = true"
                  >邮件通知</el-button
                >
                <el-button type="primary" size="small" @click="generateReport"
                  >导出数据</el-button
                >
              </el-col> -->
            </el-row>
            <div class="statistics_top">
              <p style="color: #9e9e9e">
                推送至今{{
                  new Date(articleParams.createdTime).toLocaleDateString()
                }}
                - {{ new Date().toLocaleDateString() }}
              </p>
              <div class="data_top_wrap">
                <div class="data_top_item">
                  <h3>{{ topParams.pushApiNum }}</h3>
                  <h3>分发媒介数</h3>
                </div>
                <div class="data_top_item">
                  <h3>{{ topParams.channelTypeNum }}</h3>
                  <h3>媒介类型</h3>
                </div>
                <div class="data_top_item">
                  <h3>{{ topParams.readNum }}</h3>
                  <h3>阅读量</h3>
                </div>
              </div>
            </div>
            <!-- <h4 style="border-bottom:1px solid #ddd;padding-bottom:20px;">推送媒介分布</h4> -->
            <div class="statistics_content">
              <el-table
                :data="detailParams"
                style="width: 100%"
                :header-cell-style="{ background: 'rgb(250,250,250)' }"
              >
                <el-table-column prop="channelName" label="媒介名称">
                </el-table-column>
                <el-table-column
                  prop="createdTime"
                  :formatter="formatData"
                  label="发布时间"
                >
                </el-table-column>
                <!-- <el-table-column prop="giveNum" label="点赞量">
                  <template slot-scope="{ row }">
                    <div class="hidd" v-if="!row.isEdit">
                      {{ row.giveNum }}
                    </div>
                    <div class="hidd" v-else>
                      <el-input v-model="currentItem.giveNum"></el-input>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="readNum" label="阅读量">
                  <template slot-scope="{ row }">
                    <div class="hidd" v-if="!row.isEdit">
                      {{ row.readNum }}
                    </div>
                    <div class="hidd" v-else>
                      <el-input v-model="currentItem.readNum"></el-input>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="collectionNum" label="收藏量">
                  <template slot-scope="{ row }">
                    <div class="hidd" v-if="!row.isEdit">
                      {{ row.collectionNum }}
                    </div>
                    <div class="hidd" v-else>
                      <el-input v-model="currentItem.collectionNum"></el-input>
                    </div>
                  </template>
                </el-table-column> -->
                <el-table-column prop="urlLink" label="外链">
                  <template slot-scope="{ row }">
                    <div class="hidd" v-if="!row.isEdit">
                      {{ row.urlLink }}
                    </div>
                    <div class="hidd" v-else>
                      <el-input v-model="currentItem.urlLink"></el-input>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="{ row }">
                    <div class="hidd" v-if="!row.isEdit">
                      <el-button type="text" @click="editItem(row)"
                        >编辑</el-button
                      >
                      <el-button type="text" @click="delItem(row)"
                        >删除</el-button
                      >
                    </div>
                    <div class="hidd" v-else>
                      <el-button type="text" @click="preserItem(row)"
                        >保存</el-button
                      >
                      <el-button type="text" @click="cancelItem(row)"
                        >取消</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- <div v-for="item in detailParams" :key="item.id"></div> -->
          </div>
          <img src="@/assets/images/2.jpg" alt="" />

          <!-- <div v-for="item in detailParams" :key="item.id"></div> -->
        </div>

      </section>
    </vue-html2pdf>
    <div id="main" style="width:1000px;height:400px;"></div>
  </div>

</template>
<script>
import VueHtml2pdf from 'vue-html2pdf'
import releaseDetail from '../release/releaseDetail.vue'
import {
  platformPageApi,
  platformExportApi,
  statisticsPieCharApi,
  articlePushDataStatisticsApi,
  detailsPlatformApi1,
  detailsPlatformApi2,
  detailsPlatformApi,
  detailsPlatEmailApi,
  hideApi,
  updPushDetailsApi,
  dataDetailsEmailApi,
  dataDetailsEmailNewApi,
  editReadNumApi,
  displayApi,
  delApi, exportWordDownApi, exportExcelDownApi
} from 'api/statistics'
import { articlecontentApi,timeTimerApi } from 'api/article'
import constant from 'constant'
import PizZip from 'pizzip'
import docxtemplater from 'docxtemplater'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver'
import { urlToBase64Api } from 'api/common'
import * as echarts from 'echarts'
import { statisticsOption } from '@/util/js/statisticsEcharts'
export default {
  components: {
    releaseDetail,
    VueHtml2pdf
  },
  props: {
    dataId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      pushList:[],
      tableList:[],
      echartData:{},
      tableLoading: false,
      topParams: {
        pushApiNum: 0, //api媒介数量
        channelTypeNum: 0, //媒介类型名称
        readNum: 0 //阅读量
      },
        topParams1: {
        },
      readNumEdit: null,
      isEditReadNum: false,
      uploadUrl: constant.GLOBAL_CONFIG.UPLOADIMG_Url,
      myHeaders: {},
      currentItem: {}, //当前编辑item
      detailParams: [], // 推送列表
      detailParams1: [], // 推送列表
      detailParams2: [], // 推送列表
      articleParams: {}, //文章详情
      articleParams1: {
      }, //文章详情
      detailId: 0,
        img2: '',
        img1: '',
      showLayout: false,
      fileBool: null,
      dialogAdd: false,
      mailForm: {
        defaultName: 'dtb@dongtaibao.cn',
        toEmailList: [],
        toEmailIpt: '',
        customSubject: ''
      },
        mailForm1: {
            defaultName: 'dtb@dongtaibao.cn',
            toEmailList: [],
            toEmailIpt: '',
            customSubject: ''
        },
        time: 20000,
      mailRules: {
        toEmailIpt: [
          { required: true, message: '请填写收件人邮箱', trigger: 'blur' }
        ],
        customSubject: [
          { required: true, message: '请填写邮件标题', trigger: 'blur' }
        ]
      },
      fileList: []
    }
  },
  created() {
      // setInterval(()=>{
      //     timeTimerApi().then(res => {
      //         if (res.code == 200) {
				  // localStorage.setItem("timeTest",JSON.stringify(res))
      //             let arr = res.result;
      //             if (arr[0].ifTrue === true) {
      //                 console.log('77777')
      //                 for (let i = 0; i < arr.length; i++) {
      //                     this.exportWordAuto('export', arr[i].id);
      //                 }
      //             }
      //         }
      //     })

      //     //

      // },this.time)

      this.ddd();
    this.detailId = this.$route.query.id || this.dataId

    this.initTopData()
    this.initDetail()
      this.echartData = {
          lenged:["小米","小张"],
          age:[18,22]
      }
  },
  computed: {
    htmlToPdfOptions() {
      return {
        isShowDown: false,
        margin: 0,
        filename: '数据统计.pdf',
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        enableLinks: true,
        html2canvas: {
          scale: 1,
          useCORS: true
        },
        jsPDF: {
          unit: 'in',
          format: 'a3',
          orientation: 'portrait'
        }
      }
    },
  },
  methods: {
      ddd() {

      },
      toEmail() {
          detailsPlatEmailApi(this.detailId).then(res => {
              this.mailForm.toEmailIpt = res.result.email;
              this.mailForm.customSubject = res.result.customSubject;
          })
          this.dialogAdd = true

      },
    creatBase64(imgUrlNetword) {

      return new Promise((resolve, reject) => {
        const loading = this.$loading({
          lock: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let hasBaseImg = ''
        let image = new Image()
        //图片地址
        image.src = imgUrlNetword
        urlToBase64Api({
          url: imgUrlNetword
        }).then(res => {
            if (res.code == 200) {
                hasBaseImg = 'data:image/png;base64,' + res.result;
            }
          loading.close();
          resolve(hasBaseImg)
        })
      })
    },
    exportExcelDownClick(){
      exportExcelDownApi(this.detailId).then(data => {
        let blob = new Blob([data.data])
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        let dateXls = this.articleParams.articleTitle;
        link.download = this.articleParams.stockName+"-"+`${dateXls}"分发报告.xlsx`
        link.href = blobUrl
        link.click()
        link.remove()
// 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
      })
    },
    exportWordDownClick(){
      exportWordDownApi(this.detailId).then( data => {
        let blob = new Blob([data.data])
// 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        let dateXls = this.articleParams.articleTitle;
        link.download = this.articleParams.stockName+"-"+`${dateXls}"分发报告.pdf`
        link.href = blobUrl
        link.click()
        link.remove()
// 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
      })
      // exportWordDownApi(this.detailId);
      // exportWordDownApi(this.detailId).then( res => {
      //   if(res.code === 200){
      //     this.$message.success("导出成功!")
      //   }
      // })
    },
    exportWordDown(fileExport) {
      const _this = this
      /**word列表文字转为图片 二进制 {%hasBaseImg} */
      function nextfn(resxx, index) {
        return new Promise((resolve, reject) => {
          if (resxx.urlLinkType == 30) {
            _this.creatBase64(resxx.urlLink).then(finUrl => {
              resxx.hasBaseImg = finUrl
              resolve('1')
              // if (index + 1 == this.allPushDetailList.length) {
              // }
            })
          } else {
            resolve('2')
          }
        })

      }
      let allIndex = 0
      this.detailParams1.map((resxx, index) => {
        nextfn(resxx, index).then(() => {
          allIndex++
          if (allIndex == this.detailParams1.length) {
            this.exportWord(fileExport == 'export' ? fileExport : '')
          }
        })
      })
    },

//Dream
      exportWordAuto(fileExport,detaileId) {
           let    customSubject = ''
           let    toEmailList = ''
           let    toEmailIpt = ''
          detailsPlatEmailApi(detaileId).then(res => {
              toEmailList  = res.result.email;
              customSubject = res.result.customSubject;
              toEmailIpt = toEmailList.split(',')
              console.log(' this.mailForm', customSubject)
              console.log(' this.mailForm1', toEmailList)
              console.log(' this.mailForm2', toEmailIpt)
			  // 获取文件信息
              articlecontentApi(detaileId).then(res => {
                  this.articleParams1 = { ...res.result }
				  let code = Math.random()*10;
                  this.articleParams1.nowTime = new Date().toLocaleDateString()
                  this.articleParams1.createdTime = new Date(
                      this.articleParams1.createdTime
                  ).toLocaleDateString()

// 获取模板内部信息
                  detailsPlatformApi(detaileId).then(res => {
                      this.detailParams2= res.result
                      this.detailParams2.map(res => {
                          for (let i = 0; i <res.list.length; i++) {
                              res.list[i].isEdit = false
                              res.list[i].createdTime = new Date(res.createdTime).toLocaleString()

                              if ( res.list[i].monthlyActivity) {
                                  let str  =  res.list[i].monthlyActivity.toString()
                                  if (str.length > 4) {
                                      res.list[i].numberCount = str.substring(0, str.length - 4) + "(万)";
                                  } else {
                                      res.list[i].numberCount ='1(万)'
                                  }
                              } else {
                                  res.list[i].numberCount = "/"
                              }

                              if ( res.list[i].remarks) {
                                  res.list[i].redBook =  res.list[i].remarks;
                              } else {
                                  res.list[i].redBook = "/"
                              }
                              if ( res.list[i].urlLinkType === 10) {
                                  res.list[i].hasText = true;
                                  res.list[i].hasHtml = '<a href="' +  res.list[i].urlLink + '">' + '查看链接' + '</a>';
                                  res.list[i].has = false;

                              } else if ( res.list[i].urlLinkType === 20) {
                                  res.list[i].hasHtml = '<a href="' +  res.list[i].urlLink + '">' + '查看截图' + '</a>';
                              } else {
                                  res.list[i].hasHtml ="/"
                              }
                          }
                      });
                      articlePushDataStatisticsApi(detaileId).then(res => {

						  
                          this.topParams1 = { ...res.result }
						  
						  


                          console.log('dddd',this.topParams1)
                          console.log('dddd',this.articleParams1)
                          console.log('dddd',this.detailParams2)
                          this.aaaa(detaileId, this.articleParams1, this.detailParams2, this.topParams1,fileExport)
                      })
                  })
              })
          })
          //这里要引入处理图片的插件，下载docxtemplater后，引入的就在其中了 
          // 读取并获得模板文件的二进制内容，放在项目中即可
		  
		  //发送短信
		  

      },
      // async     aaaa(detaileId, articleParams1, detailParams2, topParams1,fileExport) {
        aaaa(detaileId, articleParams1, detailParams2, topParams1,fileExport) {
          var ImageModule = require('docxtemplater-image-module-free')
          var HtmlModule = require('docxtemplater-html-module')
          var fs = require('fs')
          let _this = this
          JSZipUtils.getBinaryContent(
              'text08.docx',
              function (error, content) {
                  if (error) {
                      throw error
                  }
                  let zip = new PizZip(content)
                  // 创建并加载docxtemplater实例对象
                  let doc = new docxtemplater()
                  let htmlModule = new HtmlModule({ tags: { p: { paragraph: true } } })
                  doc.attachModule(htmlModule)
                  //  doc.attachModule(new ImageModule(opts))
                  doc.loadZip(zip)

                  doc.setData({
                      ...articleParams1,
                      ...topParams1,
                      table: detailParams2,
                      //img1: url,
                      /* img2: _this.img2,
                       img1: _this.img1*/
                  })

                  try {
                      // 用模板变量的值替换所有模板变量
                      doc.render()
                  } catch (error) {
                      // 抛出异常
                      let e = {
                          message: error.message,
                          name: error.name,
                          stack: error.stack,
                          properties: error.properties
                      }

                      throw error
                  }

                  // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
                  let out = doc.getZip().generate({
                      type: 'blob',
                      mimeType:
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  })

                  // 将目标文件对象保存为目标类型的文件，并命名
                  if (fileExport == 'export') {
					  
					  let code = Math.random()*10;
					  
                      let formData = new FormData() //创建formdata来存文件
					  
					  console.log("测试222:"+code+articleParams1.articleTitle)
					  
                      const chunkfile = new File(
                          [out],
                          // `${articleParams1.stockName}-${articleParams1.articleTitle}分发报告.docx`
						  
						  articleParams1.articleTitle+"分发报告.docx"
						  // code+"分发报告.docx"
                      )

                      let    customSubject = ''
                      let    toEmailList = ''
                      let    toEmailIpt = ''
                      detailsPlatEmailApi(detaileId).then(res => {
                          toEmailList  = res.result.email;
                          customSubject = res.result.customSubject;
                          toEmailIpt = toEmailList.split(':')
                          formData.append('file', chunkfile) //存入文件
                          formData.append('toEmailList', toEmailIpt)
                          formData.append('customSubject', customSubject)
                          dataDetailsEmailApi(formData).then(res => {
                              console.log('dddd',res)
                              if (res.data.code == 200) {
                                  // _this.dialogAdd = false
                                  _this.$message.success('发送成功')

                              }
                          })
                      })

                  }

              })
      },
    // 点击导出word
    exportWord(fileExport) {
      //这里要引入处理图片的插件，下载docxtemplater后，引入的就在其中了
      var ImageModule = require('docxtemplater-image-module-free')
      var HtmlModule = require('docxtemplater-html-module')
      var fs = require('fs')
      // const expressions = require('angular-expressions')

      let _this = this

      // 读取并获得模板文件的二进制内容，放在项目中即可
      JSZipUtils.getBinaryContent(
        'text08.docx',
        function (error, content) {
          if (error) {
            throw error
          }

            statisticsPieCharApi(_this.detailId).then(res => {
                let arr = []
                let arr1 = []
              /*  res.result.list.map((item, index) => {
                    if (index > 5) {
                    } else {
                        arr.push({

                            name: item.channelTypeName
                        })
                        arr1.push({
                            value: item.pushApiNum,

                        })
                    }

                })*/

                for (let i = 0; i <res.result.list.length; i++) {
                    arr.push(res.result.list[i].channelTypeName)
                    arr1.push(res.result.list[i].pushApiNum)
                }



            var myChart = echarts.init(document.getElementById('main'));

            // 指定图表的配置项和数据
            var
                option = {
                    animation:false,

                    xAxis: {
                        type: 'category',
                        data: arr
                    },
                    yAxis: {
                        type: 'value',
                        min:0,
                        max:10
                    },
                    series: [
                        {
                            data: arr1,
                            type: 'bar',
                            label: {
                                show: true,
                                position: 'top'
                            },

                            itemStyle: {
                                normal: {
                                    //这里是颜色
                                    color: function(params) {
                                        //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                                        var colorList = ['#00A3E0','#9d2933', '#fa8c35', '#fff143', '#789262','#424c50', '#fcefe8'];
                                        return colorList[params.dataIndex]
                                    }
                                }
                            }
                        }
                    ]
                };

            option && myChart.setOption(option);

            _this.img2 =  myChart.getDataURL({
                type: 'jpg',
                pixelRatio: 1,
                backgroundColor:'white'
            })


          // 图片处理
          let opts = {}
          opts = { centered: false }
          opts.getImage = chartId => {
            return _this.base64DataURLToArrayBuffer(chartId)
          }
          opts.getSize = function (img, tagValue, tagName) {
            //自定义指定图像大小，此处可动态调试各别图片的大小
            if (tagName === 'img2') return [700, 500]
            return [50, 50]
          }

          // 创建一个PizZip实例，内容为模板的内容
          let zip = new PizZip(content)
          // 创建并加载docxtemplater实例对象
          let doc = new docxtemplater()
          let htmlModule = new HtmlModule({ tags: { p: { paragraph: true } } })
          doc.attachModule(htmlModule)
          doc.attachModule(new ImageModule(opts))
          doc.loadZip(zip)

          doc.setData({
            ..._this.articleParams,
            ..._this.topParams,
            table: _this.detailParams1,
            //img1: url,
            img2: _this.img2,
            img1: _this.img1
          })
         console.log('jdhkjhd',_this.detailParams1)
         console.log('articleParams',_this.articleParams)
          try {
            // 用模板变量的值替换所有模板变量
            doc.render()
          } catch (error) {
            // 抛出异常
            let e = {
              message: error.message,
              name: error.name,
              stack: error.stack,
              properties: error.properties
            }

            throw error
          }

          // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
          let out = doc.getZip().generate({
            type: 'blob',
            mimeType:
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          })
          // 将目标文件对象保存为目标类型的文件，并命名
          if (fileExport == 'export') {
            let formData = new FormData() //创建formdata来存文件
            const chunkfile = new File(
              [out],
              `${_this.articleParams.stockName}-${_this.articleParams.articleTitle}分发报告.docx`
            )
            formData.append('file', chunkfile) //存入文件
            formData.append('toEmailList', _this.mailForm.toEmailList)
            formData.append('customSubject', _this.mailForm.customSubject)
            dataDetailsEmailApi(formData).then(res => {

              if (res.data.code == 200) {
                _this.dialogAdd = false
                _this.$message.success('发送成功')
              }
            })
          } else {
            saveAs(
              out,
              `${_this.articleParams.stockName}-${_this.articleParams.articleTitle}-分发报告-${_this.articleParams.nowTime}.docx`

            )

          }
        }
      )
        })
    },
    // 导出echarts图片，格式转换，官方自带，不需要修改
    base64DataURLToArrayBuffer(dataURL) {
      const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/
      if (!base64Regex.test(dataURL)) {
        return false
      }
      const stringBase64 = dataURL.replace(base64Regex, '')
      let binaryString
      if (typeof window !== 'undefined') {
        binaryString = window.atob(stringBase64)
      } else {
        binaryString = new Buffer(stringBase64, 'base64').toString('binary')
      }
      const len = binaryString.length
      const bytes = new Uint8Array(len)
      for (let i = 0; i < len; i++) {
        const ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes.buffer
    },
	//邮件通知发送
    addFriend() {
      dataDetailsEmailNewApi(this.detailId).then( res => {
        this.isShowDown = false
        this.dialogAdd = false
        this.$message.success('发送成功')
      })

      // this.$refs.mailRef.validate(async valid => {
      //   if (valid) {
      //     this.mailForm.toEmailList = this.mailForm.toEmailIpt.split(':')
      //     if (this.mailForm.toEmailList.length <= 5) {
      //       // this.generateReport().then(bool => {
      //       //   if (bool) {
      //       //     this.isShowDown = true
      //       //   }
      //       // })
			//
			// //调导出报告功能
      //       this.exportWordDown('export')
      //     } else {
      //       this.$message.error('最多填写五个邮箱')
      //       return
      //     }
      //   }
      // })
    },
    hasDownloadedPdf(e) {
      this.fileBool = e

      if (this.isShowDown) {
        let formData = new FormData() //创建formdata来存文件
        const chunkfile = new File([e], '数据统计.pdf')
        formData.append('file', chunkfile) //存入文件
        formData.append('toEmailList', this.mailForm.toEmailList)
        formData.append('customSubject', this.mailForm.customSubject)
        dataDetailsEmailApi(formData).then(res => {
          this.isShowDown = false
          this.dialogAdd = false
          this.$message.success('发送成功')
        })
      }
      // {
      //     toEmailList: this.mailForm.toEmailList,
      //     file: e,
      //     customSubject: this.mailForm.customSubject
      //   }
    },
    generateReport() {
      return new Promise((resolve, reject) => {
        this.$refs.html2Pdf.generatePdf()
        resolve(true)
      })
    },
    //初始化推送列表
    initDetail() {
      this.tableLoading = true
      // detailsPlatformApi1(this.detailId).then(res => {
          detailsPlatformApi2(this.detailId).then(res => {
        this.tableLoading = false
        this.pushList = res.result
            // localStorage.setItem("测试数据111",JSON.stringify(this.pushList))

        this.pushList.map(res => {
              res.detailParams.isEdit = false
              res.detailParams.createdTime = new Date(res.detailParams.createdTime).toLocaleString()
            if (res.detailParams.monthlyActivity) {
                let str  = res.detailParams.monthlyActivity.toString()
                if (str.length > 4) {
                    res.detailParams.numberCount = str.substring(0,str.length - 4)+"(万)";
                }
            } else {
                res.detailParams.numberCount = "/"
            }

            if (res.detailParams.remarks) {
                res.detailParams.redBook = res.detailParams.remarks;
            } else {
                res.detailParams.redBook = "/"
            }
           console.log('res',res)
            if (res.detailParams.urlLinkType === 10) {
              res.detailParams.hasText = true;
                res.detailParams.hasHtml = '<a href="' + res.detailParams.urlLink + '">' + '查看链接' + '</a>';
                res.detailParams.has = false;
            } else if (res.detailParams.urlLinkType === 20) {
                res.detailParams.hasHtml = '<a href="' + res.detailParams.urlLink + '">' + '查看截图' + '</a>';
            } else {
                res.detailParams.hasHtml ="/"
            }

          });

      })
        detailsPlatformApi(this.detailId).then(res => {
            this.detailParams1 = res.result
            console.log('  this.detailParams1',  this.detailParams1)
            this.detailParams1.map(res => {
                for (let i = 0; i <res.list.length; i++) {
                    res.list[i].isEdit = false
                    res.list[i].createdTime = new Date(res.createdTime).toLocaleString()

                    if ( res.list[i].monthlyActivity) {
                        let str  =  res.list[i].monthlyActivity.toString()
                        if (str.length > 4) {
                            res.list[i].numberCount = str.substring(0, str.length - 4) + "(万)";
                        } else {
                            res.list[i].numberCount ='1(万)'
                        }


                    } else {
                        res.list[i].numberCount = "/"
                    }

                    if ( res.list[i].remarks) {
                        res.list[i].redBook =  res.list[i].remarks;
                    } else {
                        res.list[i].redBook = "/"
                    }


                    if ( res.list[i].urlLinkType === 10) {
                        res.list[i].hasText = true;
                        res.list[i].hasHtml = '<a href="' +  res.list[i].urlLink + '">' + '查看链接' + '</a>';
                        res.list[i].has = false;

                    } else if ( res.list[i].urlLinkType === 20) {
                        res.list[i].hasHtml = '<a href="' +  res.list[i].urlLink + '">' + '查看截图' + '</a>';
                    } else {
                        res.list[i].hasHtml ="/"
                    }
                }
            });


        })
      articlecontentApi(this.detailId).then(res => {
        this.articleParams = { ...res.result }
        this.articleParams.nowTime = new Date().toLocaleDateString()
        this.articleParams.createdTime = new Date(
          this.articleParams.createdTime
        ).toLocaleDateString()

      })

    },
    //初始化文章信息
    initTopData() {
      articlePushDataStatisticsApi(this.detailId).then(res => {
            this.topParams = { ...res.result }
        })
    },
    //编辑阅读量
    editReadNum(bool) {
      if (bool) {
        editReadNumApi({
          id: this.detailId,
          data: this.readNumEdit
        }).then(res => {
          if (res.code == 200) {
            this.$message.success('编辑成功')
            this.initTopData()
            this.isEditReadNum = false
            this.readNumEdit = null
          }
        })
      } else {
        this.isEditReadNum = false
        this.readNumEdit = null
      }
    },
    showEditReadNum() {
      this.isEditReadNum = true
      this.readNumEdit = this.topParams.readNum
    },
    //点击取消
    cancelItem(row) {
      this.currentItem = {}
      let arr = JSON.parse(JSON.stringify(this.detailParams))
      arr.map(res => {
        res.isEdit = false
      })
      this.detailParams = arr
    },
    //点击编辑
    editItem(row) {
      row.urlLinkType = row.urlLinkType ? String(row.urlLinkType) : ''
      this.currentItem = { ...row }
      detailsPlatformApi1(this.detailId).then(res => {
        // this.
      })

      let arr = JSON.parse(JSON.stringify(this.pushList))

      arr.forEach(item => {
        item.detailParams.map(res => {
          if (res.id == row.id) {
            res.isEdit = true
          } else {
            res.isEdit = false
          }
        })
      })
      // arr.map(res => {
      //   console.log("resID"+res.id);
      //   console.log("rowID"+row.id);
      //   if (res.id == row.id) {
      //     res.isEdit = true
      //   } else {
      //     res.isEdit = false
      //   }
      // })
      this.pushList = arr
    },
    //隐藏
    delItem(row) {
      hideApi(row.id).then(res => {
        if (res.code == 200) {
          this.$message.success('屏蔽成功')
          this.initDetail()
        }
      })
    },
    //显示
    hideShowBtn(row) {
      displayApi(row.id).then(res => {
        if (res.code == 200) {
          this.$message.success('显示成功')
          this.initDetail()
        }
      })
    },
    //删除
    delItemmth(row) {
      delApi(row.id).then(res => {
        if (res.code == 200) {
          this.$message.success('删除成功')
          this.initDetail()
        }
      })
    },
    //点击保存
    preserItem(row) {

      updPushDetailsApi({ ...this.currentItem }).then(res => {
        if (res.code == 200) {
          this.$message.success('保存成功')
          this.cancelItem()
          this.initDetail()
        }
      })
    },
    //时间
    formatData(row, column, cellValue, index) {
      //把传过来的日期进行回炉重造一下，又创建了一个js的 Date对象，进行重新构造，转为String字符串
      //最终返回 s 就可以了
      var s = new Date(cellValue).toLocaleString()
      return s

      // 测试参数含义：不知道参数是什么含义的就打印出来
    },
    uploadChange(file, fileList) {
      this.fileList = fileList
    },
    uploadReturn(res) {
      if (res.code === 200) {
        this.currentItem.urlLink = res.result.url
      }
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message({
          message: '图片只支持上传jpg,jpeg,png格式!',
          type: 'error'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '图片大小不能超过 2MB!',
          type: 'error'
        })
      }
      return isJPG && isLt2M
    },
    handleExceed() {
      this.$message.warning(`当前限制上传 1 张图片`)
    },
    companyUploadRemove(file) {
      this.currentItem.urlLinkfaceDocId = null
      this.fileList = this.fileList.filter(item => {
        return item.url != file.url
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.data_statistics {
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
    margin-bottom: 20px;
  }
  .statistics_detail {
    background: #fff;
    width: 95%;
    border-radius: 3px;
    padding: 20px;
    padding-top: 10px;
    margin: 0 auto;
    .data_top_wrap {
      // width: 95%;
      // margin: 0 auto;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      .data_top_item {
        width: 33%;
        text-align: center;
        font-size: 20px;
        border-right: 1px solid #ddd;
        padding: 10px 0;
        h3 {
          margin: 5px 0;
        }
        &:nth-child(1) {
          color: #f59a23;
        }
        &:nth-child(2) {
          color: #7cbfae;
        }
        &:nth-child(3) {
          color: #8080ff;
          border: none;
        }
      }
    }
    /deep/.el-upload {
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #999;
    }
    /deep/.avatar-uploader {
      width: 80px;
      height: 80px;
      img {
        width: 80px;
        height: 80px;
      }
    }
    // /deep/.el-upload-list--picture-card .el-upload-list__item {
    // }
  }
}

.el-table th{
  background-color: red;

}

.el-table th .cell{
  color: green;
}
</style>
