<template>
  <div class="home-wrap">
    <el-row>
      <el-col :span="24">
                <div class="home-top-search">
                  <el-form
                    :model="searchFormData"
                    ref="formData"
                    size="small"
                    label-position="right"
                    label-width="70px"
                  >
                    <el-row class="text-left">
                      <h1>首页动态</h1>
                      <el-col :span="6">
                        <el-form-item label='时间:'>
                          <el-select v-model="dateType" placeholder="请选择">
                            <el-option
                              v-for="item in dateOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="1"><h1></h1></el-col>
                      <el-col :span="8">
                        <el-button
                          type="primary"
                          size="small"
                          @click="searchData(true)"
                        >
                          查询
                        </el-button>
                        <el-button size="small" @click="searchData(false)">
                          重置
                        </el-button>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
      </el-col>
    </el-row>
    <div class="welcome">
      <div class="content_left">
        <div class="release">
          <h3>分发数据</h3>
          <div class="content_num">
            <div class="item">
              <p>分发数</p>
              <h4>{{ topData.pushTotalNum }}</h4>
            </div>
            <div class="item">
              <p>有效率</p>
              <h4>{{ topData.validRate }}</h4>
            </div>
<!--            <div class="item">-->
<!--              <p>机审率</p>-->
<!--              <h4>{{ topData.machineAuditRate }}</h4>-->
<!--            </div>-->
            <div class="item">
              <p>媒介数</p>
              <h4>{{ topData.pushApiNum }}</h4>
            </div>

            <div class="item">
              <p>回参率</p>
              <h4>{{ topData.validPushRate }}</h4>
            </div>

<!--            <div class="item">-->
<!--              <p>回参率（包含说明页）</p>-->
<!--              <h4>{{ topData.validPushRateExplain }}</h4>-->
<!--            </div>-->


<!--            <div class="item">-->
<!--              <p>平均媒介数</p>-->
<!--              <h4>{{ topData.pushAverageNum }}</h4>-->
<!--            </div>-->
            <div class="item">
              <p>阅读量</p>
              <h4>{{ topData.readNum }}</h4>
            </div>
          </div>
          <div class="statistics">
            <div class="statistics_header">
              <h3>媒介分析</h3>
            </div>
            <div class="statistics_date">
              <div class="statistics_date_box">
                <colors-bar
                  ref="chart15"
                  :dataArr="colorsData"
                  :xdataArr="xcolorData"
                ></colors-bar>
              </div>
              <div class="statistics_ranking">
                <div class="statistics_ranking_left">
                  <h4>企业动态排名</h4>
                  <div class="ranking_left_list">
                    <p v-for="(d, index) in rankArr" :key="d.id">
                      <b :class="index < 3 ? 'rank-b' : 'no-b'">{{
                          index + 1
                        }}</b
                      >
                      <span>{{ d.articleTitle }}</span>
                    </p>
                  </div>
                </div>
                <div class="statistics_ranking_right">
                  <h4>阅读量</h4>
                  <div class="ranking_right_list">
                    <!--                    <p v-for='(d,index) in articleData'>-->
                    <!--                      <span>{{ d.articleTitle }}</span>-->
                    <!--                    </p>-->
                    <p v-for="d in rankArr" :key="d.id">
                      {{ d.readNum }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content_right">
        <div class="content_right_wrap">
          <div class="expect">
            <h4>总分发数:{{allReportNum}}</h4>
            <div class="expect_charts">
<!--              <h3>发布24h媒介数</h3>-->
<!--              <h3>总分发数</h3>-->
              <div class="expect_charts_item">
                <chart-box :ref="'expectChart'" />
              </div>
            </div>
          </div>
          <div class="rate">
            <h4>总有效率</h4>
            <div class="rate-chart">
              <chart-box :ref="'rateChart'" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content_left_bottom">
      <div class="bottom_content_wrap">
        <div class="manuscript">
          <div class="manuscript_head">
            <h3>企业动态</h3>
<!--            <p @click="$router.push('/release')">更多</p>-->
          </div>
          <div class="manus-table">
            <el-table
              v-loading="tableLoading"
              :data="tableData"
              style="width: 100%; margin-bottom: 20px"
              row-key="id"
              header-row-class-name="tableHead"
              :header-cell-style="{ background: 'rgb(250,250,250)' }"
            >

              <el-table-column
                prop="stockName"
                label="公司名称"
                header-align="center"
                align="center">

              </el-table-column>


              <el-table-column
                prop="articleTitle"
                label="标题"
                header-align="center"
                align="center"
              >
              </el-table-column>

              <el-table-column
                prop="updatedTime"
                label="分发时间"
                header-align="center"
                align="center"
              >
                <template slot-scope="{ row }">
                  {{ utils.timeToYyMmDd(row.checkTime) }}
                </template>
              </el-table-column>


              <el-table-column
                prop="pushApiSum"
                label="分发媒介数"
                header-align="center"
                align="center">

              </el-table-column>


              <el-table-column
                label="操作"
                header-align="center"
                align="center"
                fixed="right"
              >
                <template slot-scope="{ row }">
                  <el-button
                    type="text"
                    size="small"
                    @click.native.prevent="lookdetail(row)"
                    v-if="userbtn.indexOf('HOME_LOOK') >= 0"
                  >
                    查看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="search_pv">
        <h4>总阅读量</h4>
        <div class="search_pv_img">
          <p>{{ allReadNum }}</p>
          <h4>PV</h4>
          <img src="@/assets/home_images/pv.png" alt="" />
        </div>
      </div>
    </div>
    <vue-html2pdf
      :show-layout="showLayout"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="文章详情"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a3"
      pdf-orientation="landscape"
      pdf-content-width="1000px"
      :html-to-pdf-options="htmlToPdfOptions"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <releaseDetail :rowID="rowID" @downPdf="downPdf" />
      </section>
    </vue-html2pdf>
    <div v-if="dataId" style="height: 1px; overflow: hidden">
      <dataStatisticsDetail
        ref="refsDataStatic"
        v-if="dataId"
        :dataId="dataId"
        style="opacity: 0"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { currencyTime } from 'util/js/tools.js'
import colorsBar from './colorsBar.vue'
import { expectFun1, rateDate } from '@/util/js/dashboard'
import VueHtml2pdf from 'vue-html2pdf'
import releaseDetail from '../release/releaseDetail.vue'
import { companyPageApi } from 'api/company'
import dataStatisticsDetail from '../dataStatistics/detail.vue'

import {
  articlePushApiRankingApi,
  articlePushChannelTypeApi,
  articlePushValidRateApi,
  dynamicStatisticsApi,
  getAllReportNumApi,
  getNumByDateApi,
  queryArticleListDataApi,
  queryPushChannelTypeApi,
  queryTopDataApi,
  totalReadNumApi
} from 'api/home'

export default {
  name: 'Home',
  data() {
    return {
      allReportNum:0,
      dateOptions:[
        {
          label:"今日",
          value:"4"
        },
        {
          label:"七天",
          value:"1"
        },
        {
          label:"近一个月",
          value:"2"
        },
        {
          label:"近一年",
          value:"3"
        },
      ],
      articleData:[],
      topData:{
        pushTotalNum:0,
        validRate:"0%",
        pushApiNum: 0,
        readNum:0,
        machineAuditRate:"0%",
        validPushRate:"0%",
        validPushRateExplain:"0%"
      },
      dateType:'4',
      searchFormData: {
        companyId: 1
      },
      dataId: null,
      actionObjTop: {},
      companyOptions: [],
      showLayout: false,
      rowID: null,
      helloUserName: '',
      dateValue: [],
      paramsValue: {
        startTime: '',
        endTime: ''
      },
      tableData: [],
      tableLoading: false,
      timeDefaultShow: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rankArr: [],
      // 交友方式柱图
      colorsData: [],
      xcolorData: [],
      allReadNum:0
    }
  },
  computed: {
    ...mapState('user', ['isLogin', 'name']),
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: '文章详情.pdf',
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        enableLinks: true,
        html2canvas: {
          scale: 2,
          useCORS: true
        },
        jsPDF: {
          unit: 'in',
          format: 'a3',
          orientation: 'portrait'
        }
      }
    }
  },
  watch: {
    name(val) {
      this.helloUserName = val
    }
  },
  created() {
    let localUserInfo = localStorage.getItem('USER_ALL_INFO')
    this.helloUserName = this.name
    if (localUserInfo && !this.name) {
      this.helloUserName = JSON.parse(localUserInfo).name
    }
    this.timeDefaultShow = new Date()
    this.timeDefaultShow.setMonth(new Date().getMonth() - 1)
    this.initArticleTable()
    this.initData()
  },
  mounted() {
    this.$nextTick(() => {
    })
  },
  methods: {
    show: function () {
      this.showLayout = !this.showLayout
    },
    getAllReportNum(){
      getAllReportNumApi().then(res=>{
        if (res.code === 200){
          this.allReportNum = res.result;
        }
      })
    },
    queryDataStatisticsFun(){
      queryPushChannelTypeApi(this.dateType).then(res => {
        if (res.code === 200){
          const result = res.result;
          const data = [];
          const channelNameArr = [];
          result.forEach(item => {
            const obj = {
              name: item.channelTypeName,
              value: item.num
            }
            channelNameArr.push(item.channelTypeName);
            data.push(obj);
          })
          console.log("colorData"+JSON.stringify(data))
          console.log("channelNameArr"+JSON.stringify(channelNameArr))
          this.colorsData = data;
          this.xcolorData = channelNameArr;
        }
      })
    },
    queryTopDataFun(){
      queryTopDataApi(this.dateType).then(res => {
        if (res.code === 200){
          const topData = {
            machineAuditRate:res.result.machineAuditRate,
            pushTotalNum:res.result.pushTotalNum,
            validRate:res.result.validRate,
            readNum:res.result.readNumStr,
            pushApiNum:res.result.avgPushApiNum,
            validPushRateExplain:res.result.validPushRateExplain+"%",
            validPushRate:res.result.validPushRate+"%"
          }
          this.topData = topData;
        }
      })
    },
    initData() {
      this.choseDateTag(7)
      this.initCompany()
      this.initNumber()
      this.queryTopDataFun();
      this.queryDataStatisticsFun();
      this.getAllReportNum();
      // this.queryArticleStatisticsFun();
    },
    //
    initNumber() {
      let companyId = this.searchFormData.companyId
      totalReadNumApi(companyId).then(res=>{
        this.allReadNum = res.result
      })
      dynamicStatisticsApi(companyId).then(res => {
        if (res.code == 200) {
          this.actionObjTop = res.result
        }
      })

      getNumByDateApi().then(res => {
        let dataObj = expectFun1()
        let dateArr = [];
        let numArr = [];
        const data = res.result;
        data.forEach(item => {
          console.log("时间"+item.date)
          // 提取日期部分（年月日）
          const dateOnly = item.date.substring(0, 10);
          console.log("年月日：" + dateOnly);

          dateArr.push(dateOnly);
          numArr.push(item.reportNum);
          // this.allReportNum+=item.reportNum
        })
        console.log("dateArr",dateArr)
        console.log("numArr",numArr)
        dataObj.xAxis.data = dateArr;
        dataObj.series[0].data = numArr;
        this.$refs.expectChart.draw(dataObj); // 使用更新后的配置绘制图表
      })
      articlePushValidRateApi(companyId).then(res => {
        if (res.code == 200) {
          // console.log(res.result)
          let dataRate = rateDate
          // console.log(res.result.validNum / res.result.total)
          dataRate.series[0].data[0].value =
            res.result.validNum / res.result.total


          console.log("测试111:"+res.result.validNum/ res.result.total)

          this.$refs.rateChart.draw(rateDate)
        }
      })
      articlePushChannelTypeApi({
        companyId: companyId,
        startTime: this.paramsValue.startTime,
        endTime: this.paramsValue.endTime
      }).then(res => {
        let arr = []
        res.result.map(res => {
          arr.push({
            name: res.channelTypeName,
            value: res.num
          })
        })
        arr.map(res => {
        })
      })
      articlePushApiRankingApi(this.dateType).then(res => {
        this.rankArr = res.result
      })
    },
    initCompany() {
      //初始化公司列表
      companyPageApi({
        pageNum: 1,
        pageSize: 100
      }).then(res => {
        if (res.code === 200) {
          this.companyOptions = res.result.records
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
      })
    },
    searchData(bool) {
      if (bool) {
      } else {
        this.searchFormData = this.$options.data().searchFormData
      }
      this.initData()
    },
    initArticleTable() {

      queryArticleListDataApi(4).then(res => {
        if (res.code === 200){
          this.tableData = res.result
        }
      })
    },
    lookdetail(row) {
      this.$router.push({
        path: '/articleDetail',
        query: {
          id: row.id
        }
      })
    },
    addAndEditDialog(row) {
      // this.rowID = row.id
      this.dataId = row.id
      setTimeout(() => {
        console.log(this.$refs.refsDataStatic)
        this.$refs.refsDataStatic.exportWordDown()
      }, 1000)
    },
    downPdf() {
      this.$refs.html2Pdf.generatePdf()
    },
    dateChange(e) {
      let companyId = this.searchFormData.companyId
      this.paramsValue.startTime = e[0] + ' 00:00:00'
      this.paramsValue.endTime = e[1] + ' 23:59:59'
      this.changeDateData(companyId)
    },
    choseDateTag(time) {
      let companyId = this.searchFormData.companyId
      this.dateValue = []
      if (time !== 0) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * time)
        let area = {
          id: 10,
          name: `近${time}天`,
          // name: currencyTime(start) + ' 至 ' + currencyTime(end),
          alias: 'timeSlot'
        }
        this.paramsValue.startTime = currencyTime(start)
        this.paramsValue.endTime = currencyTime(end)
      } else {
        // if (findIndex !== -1) {
        //   this.paramsValue.startTime = ''
        //   this.paramsValue.endTime = ''
        //   this.screening.condition.splice(findIndex, 1)
        // }
      }
      // console.log(this.paramsValue)
      this.changeDateData(companyId)
    },
    changeDateData(companyId) {
      // articlePushChannelTypeApi({
      //   companyId: companyId,
      //   startTime: this.paramsValue.startTime,
      //   endTime: this.paramsValue.endTime
      // }).then(res => {
      //   let arr = []
      //   res.result.map(res => {
      //     arr.push({
      //       name: res.channelTypeName,
      //       value: res.num
      //     })
      //   })
      //   this.colorsData = arr
      // })
      articlePushApiRankingApi(this.dateType).then(res => {
        this.rankArr = res.result
      })
    }
  },
  components: {
    colorsBar,
    releaseDetail,
    VueHtml2pdf,
    dataStatisticsDetail
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
