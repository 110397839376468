var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-wrap" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "home-top-search" },
              [
                _c(
                  "el-form",
                  {
                    ref: "formData",
                    attrs: {
                      model: _vm.searchFormData,
                      size: "small",
                      "label-position": "right",
                      "label-width": "70px",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      { staticClass: "text-left" },
                      [
                        _c("h1", [_vm._v("首页动态")]),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "时间:" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.dateType,
                                      callback: function ($$v) {
                                        _vm.dateType = $$v
                                      },
                                      expression: "dateType",
                                    },
                                  },
                                  _vm._l(_vm.dateOptions, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("el-col", { attrs: { span: 1 } }, [_c("h1")]),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.searchData(true)
                                  },
                                },
                              },
                              [_vm._v(" 查询 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.searchData(false)
                                  },
                                },
                              },
                              [_vm._v(" 重置 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "welcome" }, [
        _c("div", { staticClass: "content_left" }, [
          _c("div", { staticClass: "release" }, [
            _c("h3", [_vm._v("分发数据")]),
            _c("div", { staticClass: "content_num" }, [
              _c("div", { staticClass: "item" }, [
                _c("p", [_vm._v("分发数")]),
                _c("h4", [_vm._v(_vm._s(_vm.topData.pushTotalNum))]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("p", [_vm._v("有效率")]),
                _c("h4", [_vm._v(_vm._s(_vm.topData.validRate))]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("p", [_vm._v("媒介数")]),
                _c("h4", [_vm._v(_vm._s(_vm.topData.pushApiNum))]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("p", [_vm._v("回参率")]),
                _c("h4", [_vm._v(_vm._s(_vm.topData.validPushRate))]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("p", [_vm._v("阅读量")]),
                _c("h4", [_vm._v(_vm._s(_vm.topData.readNum))]),
              ]),
            ]),
            _c("div", { staticClass: "statistics" }, [
              _vm._m(0),
              _c("div", { staticClass: "statistics_date" }, [
                _c(
                  "div",
                  { staticClass: "statistics_date_box" },
                  [
                    _c("colors-bar", {
                      ref: "chart15",
                      attrs: {
                        dataArr: _vm.colorsData,
                        xdataArr: _vm.xcolorData,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "statistics_ranking" }, [
                  _c("div", { staticClass: "statistics_ranking_left" }, [
                    _c("h4", [_vm._v("企业动态排名")]),
                    _c(
                      "div",
                      { staticClass: "ranking_left_list" },
                      _vm._l(_vm.rankArr, function (d, index) {
                        return _c("p", { key: d.id }, [
                          _c("b", { class: index < 3 ? "rank-b" : "no-b" }, [
                            _vm._v(_vm._s(index + 1)),
                          ]),
                          _c("span", [_vm._v(_vm._s(d.articleTitle))]),
                        ])
                      }),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "statistics_ranking_right" }, [
                    _c("h4", [_vm._v("阅读量")]),
                    _c(
                      "div",
                      { staticClass: "ranking_right_list" },
                      _vm._l(_vm.rankArr, function (d) {
                        return _c("p", { key: d.id }, [
                          _vm._v(" " + _vm._s(d.readNum) + " "),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "content_right" }, [
          _c("div", { staticClass: "content_right_wrap" }, [
            _c("div", { staticClass: "expect" }, [
              _c("h4", [_vm._v("总分发数:" + _vm._s(_vm.allReportNum))]),
              _c("div", { staticClass: "expect_charts" }, [
                _c(
                  "div",
                  { staticClass: "expect_charts_item" },
                  [_c("chart-box", { ref: "expectChart" })],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "rate" }, [
              _c("h4", [_vm._v("总有效率")]),
              _c(
                "div",
                { staticClass: "rate-chart" },
                [_c("chart-box", { ref: "rateChart" })],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content_left_bottom" }, [
        _c("div", { staticClass: "bottom_content_wrap" }, [
          _c("div", { staticClass: "manuscript" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "manus-table" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tableLoading,
                        expression: "tableLoading",
                      },
                    ],
                    staticStyle: { width: "100%", "margin-bottom": "20px" },
                    attrs: {
                      data: _vm.tableData,
                      "row-key": "id",
                      "header-row-class-name": "tableHead",
                      "header-cell-style": { background: "rgb(250,250,250)" },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "stockName",
                        label: "公司名称",
                        "header-align": "center",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "articleTitle",
                        label: "标题",
                        "header-align": "center",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "updatedTime",
                        label: "分发时间",
                        "header-align": "center",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.utils.timeToYyMmDd(row.checkTime)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "pushApiSum",
                        label: "分发媒介数",
                        "header-align": "center",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        "header-align": "center",
                        align: "center",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm.userbtn.indexOf("HOME_LOOK") >= 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.lookdetail(row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 查看 ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "search_pv" }, [
          _c("h4", [_vm._v("总阅读量")]),
          _c("div", { staticClass: "search_pv_img" }, [
            _c("p", [_vm._v(_vm._s(_vm.allReadNum))]),
            _c("h4", [_vm._v("PV")]),
            _c("img", {
              attrs: { src: require("@/assets/home_images/pv.png"), alt: "" },
            }),
          ]),
        ]),
      ]),
      _c(
        "vue-html2pdf",
        {
          ref: "html2Pdf",
          attrs: {
            "show-layout": _vm.showLayout,
            "float-layout": true,
            "enable-download": true,
            "preview-modal": true,
            "paginate-elements-by-height": 1400,
            filename: "文章详情",
            "pdf-quality": 2,
            "manual-pagination": false,
            "pdf-format": "a3",
            "pdf-orientation": "landscape",
            "pdf-content-width": "1000px",
            "html-to-pdf-options": _vm.htmlToPdfOptions,
          },
        },
        [
          _c(
            "section",
            { attrs: { slot: "pdf-content" }, slot: "pdf-content" },
            [
              _c("releaseDetail", {
                attrs: { rowID: _vm.rowID },
                on: { downPdf: _vm.downPdf },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.dataId
        ? _c(
            "div",
            { staticStyle: { height: "1px", overflow: "hidden" } },
            [
              _vm.dataId
                ? _c("dataStatisticsDetail", {
                    ref: "refsDataStatic",
                    staticStyle: { opacity: "0" },
                    attrs: { dataId: _vm.dataId },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "statistics_header" }, [
      _c("h3", [_vm._v("媒介分析")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "manuscript_head" }, [
      _c("h3", [_vm._v("企业动态")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }