var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data_statistics" },
    [
      _c(
        "div",
        { staticClass: "channelManagement-head" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$router.push("/dataStatistics")
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "statistics_detail" },
        [
          _c(
            "el-row",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "border-bottom": "1px solid #ddd",
              },
            },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "h4",
                  { staticStyle: { "font-size": "25px", margin: "20px 0" } },
                  [_vm._v(" " + _vm._s(_vm.articleParams.articleTitle) + " ")]
                ),
              ]),
              _c(
                "el-col",
                { staticClass: "text-right", attrs: { span: 12 } },
                [
                  _vm.userbtn.indexOf("DATASTATISITICE_MAIL_EXPORT") >= 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.toEmail },
                        },
                        [_vm._v("邮件通知")]
                      )
                    : _vm._e(),
                  _vm.userbtn.indexOf("DATASTATISITICE_DETAIL_EXPORT") >= 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.exportWordDownClick },
                        },
                        [_vm._v("导出报告")]
                      )
                    : _vm._e(),
                  _vm.userbtn.indexOf("DATASTATISITICE_DETAIL_EXPORT") >= 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.exportExcelDownClick },
                        },
                        [_vm._v("导出表格")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "statistics_top" }, [
            _c("p", { staticStyle: { color: "#9e9e9e" } }, [
              _vm._v(
                " 分发至今 " +
                  _vm._s(_vm.articleParams.createdTime) +
                  " - " +
                  _vm._s(_vm.articleParams.nowTime) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "data_top_wrap" }, [
              _c("div", { staticClass: "data_top_item" }, [
                _c("h3", [_vm._v(_vm._s(_vm.topParams.pushApiNum))]),
                _c("h3", [_vm._v("分发媒介数")]),
              ]),
              _c("div", { staticClass: "data_top_item" }, [
                _c("h3", [_vm._v(_vm._s(_vm.topParams.channelTypeNum))]),
                _c("h3", [_vm._v("媒介类型")]),
              ]),
              _c("div", { staticClass: "data_top_item" }, [
                !_vm.isEditReadNum
                  ? _c("h3", [_vm._v(_vm._s(_vm.topParams.readNum))])
                  : _vm._e(),
                _vm.isEditReadNum
                  ? _c(
                      "div",
                      [
                        _c("el-input-number", {
                          model: {
                            value: _vm.readNumEdit,
                            callback: function ($$v) {
                              _vm.readNumEdit = $$v
                            },
                            expression: "readNumEdit",
                          },
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editReadNum(true)
                              },
                            },
                          },
                          [_vm._v("保存")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editReadNum(false)
                              },
                            },
                          },
                          [_vm._v("取消")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("h3", [
                  _vm._v(" 阅读量"),
                  _vm.userbtn.indexOf("DATASTATISITICE_EDIT_READNUM") >= 0
                    ? _c("i")
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
          _vm._l(_vm.pushList, function (item) {
            return _c(
              "div",
              { staticClass: "statistics_content" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "header-cell-style": {
                        background: "#B0C4DE",
                        color: "#606266",
                      },
                      data: item.detailParams,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "channelName", label: item.brokerName },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createdTime",
                        formatter: _vm.formatData,
                        label: "发布时间",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "urlLink", label: "链接/截图" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                !row.isEdit
                                  ? _c("div", { staticClass: "hidd" }, [
                                      row.urlLinkType == 10
                                        ? _c("span", [
                                            _vm._v(
                                              " " + _vm._s(row.urlLink) + " "
                                            ),
                                          ])
                                        : _vm._e(),
                                      row.urlLinkType == 20
                                        ? _c("img", {
                                            attrs: {
                                              src: row.urlLink,
                                              alt: "",
                                              width: "80px",
                                              height: "80px",
                                            },
                                          })
                                        : _vm._e(),
                                    ])
                                  : _c(
                                      "div",
                                      { staticClass: "hidd" },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            model: {
                                              value:
                                                _vm.currentItem.urlLinkType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.currentItem,
                                                  "urlLinkType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "currentItem.urlLinkType",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "链接",
                                                value: "10",
                                              },
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "图片",
                                                value: "20",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.currentItem.urlLinkType == 10
                                          ? _c("el-input", {
                                              model: {
                                                value: _vm.currentItem.urlLink,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.currentItem,
                                                    "urlLink",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "currentItem.urlLink",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.currentItem.urlLinkType == 20
                                          ? _c(
                                              "el-upload",
                                              {
                                                staticClass: "avatar-uploader",
                                                attrs: {
                                                  action: _vm.uploadUrl,
                                                  headers: _vm.myHeaders,
                                                  accept:
                                                    "image/jpeg,image/jpg,image/png",
                                                  "show-file-list": false,
                                                  "on-success":
                                                    _vm.uploadReturn,
                                                  "before-upload":
                                                    _vm.beforeAvatarUpload,
                                                  "on-change": _vm.uploadChange,
                                                  "on-exceed": _vm.handleExceed,
                                                  "on-remove":
                                                    _vm.companyUploadRemove,
                                                },
                                              },
                                              [
                                                _vm.currentItem.urlLink
                                                  ? _c("img", {
                                                      staticClass: "avatar",
                                                      attrs: {
                                                        src: _vm.currentItem
                                                          .urlLink,
                                                      },
                                                    })
                                                  : _c("i", {
                                                      staticClass:
                                                        "el-icon-plus avatar-uploader-icon",
                                                    }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                !row.isEdit
                                  ? [
                                      _vm.userbtn.indexOf(
                                        "DATASTATISITICE_ITEM_EDIT"
                                      ) >= 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editItem(row)
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          )
                                        : _vm._e(),
                                      _vm.userbtn.indexOf(
                                        "DATASTATISITICE_ITEM_HIDE"
                                      ) >= 0 && !row.deleteFlag
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delItem(row)
                                                },
                                              },
                                            },
                                            [_vm._v("屏蔽")]
                                          )
                                        : _vm._e(),
                                      _vm.userbtn.indexOf(
                                        "DATASTATISITICE_ITEM_SHOW"
                                      ) >= 0 && row.deleteFlag
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.hideShowBtn(row)
                                                },
                                              },
                                            },
                                            [_vm._v("显示")]
                                          )
                                        : _vm._e(),
                                      _vm.userbtn.indexOf(
                                        "DATASTATISITICE_ITEM_DEL"
                                      ) >= 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delItemmth(row)
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e(),
                                    ]
                                  : [
                                      _vm.userbtn.indexOf(
                                        "DATASTATISITICE_ITEM_SAVE"
                                      ) >= 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.preserItem(row)
                                                },
                                              },
                                            },
                                            [_vm._v("保存")]
                                          )
                                        : _vm._e(),
                                      _vm.userbtn.indexOf(
                                        "DATASTATISITICE_ITEM_CANCEL"
                                      ) >= 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.cancelItem(row)
                                                },
                                              },
                                            },
                                            [_vm._v("取消")]
                                          )
                                        : _vm._e(),
                                    ],
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: "邮件通知",
            visible: _vm.dialogAdd,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAdd = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "mailRef",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.mailForm,
                rules: _vm.mailRules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "发件人:", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.mailForm.defaultName,
                      callback: function ($$v) {
                        _vm.$set(_vm.mailForm, "defaultName", $$v)
                      },
                      expression: "mailForm.defaultName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件人邮箱:", prop: "toEmailIpt" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.mailForm.toEmailIpt,
                      callback: function ($$v) {
                        _vm.$set(_vm.mailForm, "toEmailIpt", $$v)
                      },
                      expression: "mailForm.toEmailIpt",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮件标题", prop: "customSubject" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.mailForm.customSubject,
                      callback: function ($$v) {
                        _vm.$set(_vm.mailForm, "customSubject", $$v)
                      },
                      expression: "mailForm.customSubject",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogAdd = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addFriend } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vue-html2pdf",
        {
          ref: "html2Pdf",
          attrs: {
            "show-layout": _vm.showLayout,
            "float-layout": true,
            "enable-download": true,
            "preview-modal": false,
            "paginate-elements-by-height": 6000,
            filename: "数据统计",
            "pdf-quality": 2,
            "manual-pagination": false,
            "pdf-format": "a3",
            "html-to-pdf-options": _vm.htmlToPdfOptions,
            "pdf-orientation": "landscape",
            "pdf-content-width": "1000px",
          },
          on: { hasDownloaded: _vm.hasDownloadedPdf },
        },
        [
          _c(
            "section",
            { attrs: { slot: "pdf-content" }, slot: "pdf-content" },
            [
              _c("div", { staticClass: "data_statistics" }, [
                _c(
                  "div",
                  { staticClass: "statistics_detail" },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/images/1.jpg"), alt: "" },
                    }),
                    _c(
                      "el-row",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "border-bottom": "1px solid #ddd",
                        },
                      },
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("h4", [
                            _vm._v(_vm._s(_vm.articleParams.articleTitle)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "statistics_top" }, [
                      _c("p", { staticStyle: { color: "#9e9e9e" } }, [
                        _vm._v(
                          " 推送至今" +
                            _vm._s(
                              new Date(
                                _vm.articleParams.createdTime
                              ).toLocaleDateString()
                            ) +
                            " - " +
                            _vm._s(new Date().toLocaleDateString()) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "data_top_wrap" }, [
                        _c("div", { staticClass: "data_top_item" }, [
                          _c("h3", [_vm._v(_vm._s(_vm.topParams.pushApiNum))]),
                          _c("h3", [_vm._v("分发媒介数")]),
                        ]),
                        _c("div", { staticClass: "data_top_item" }, [
                          _c("h3", [
                            _vm._v(_vm._s(_vm.topParams.channelTypeNum)),
                          ]),
                          _c("h3", [_vm._v("媒介类型")]),
                        ]),
                        _c("div", { staticClass: "data_top_item" }, [
                          _c("h3", [_vm._v(_vm._s(_vm.topParams.readNum))]),
                          _c("h3", [_vm._v("阅读量")]),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "statistics_content" },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.detailParams,
                              "header-cell-style": {
                                background: "rgb(250,250,250)",
                              },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "channelName", label: "媒介名称" },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "createdTime",
                                formatter: _vm.formatData,
                                label: "发布时间",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "urlLink", label: "外链" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      !row.isEdit
                                        ? _c("div", { staticClass: "hidd" }, [
                                            _vm._v(
                                              " " + _vm._s(row.urlLink) + " "
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            { staticClass: "hidd" },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.currentItem.urlLink,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.currentItem,
                                                      "urlLink",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "currentItem.urlLink",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "操作" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      !row.isEdit
                                        ? _c(
                                            "div",
                                            { staticClass: "hidd" },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editItem(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("编辑")]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delItem(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "hidd" },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.preserItem(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("保存")]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.cancelItem(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("取消")]
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("img", {
                  attrs: { src: require("@/assets/images/2.jpg"), alt: "" },
                }),
              ]),
            ]
          ),
        ]
      ),
      _c("div", {
        staticStyle: { width: "1000px", height: "400px" },
        attrs: { id: "main" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }