import apiServer from 'api/apiServer.js'

// 动态统计
export function dynamicStatisticsApi(params = {}) {
  let url = `/dashboard/platform/dynamicStatistics/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

// 小时媒介分布图
export function hourChannelDistributionApi(params = {}) {
  let url = `/dashboard/platform/hourChannelDistribution/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

// 文章发布有效率
export function articlePushValidRateApi(params = {}) {
  let url = `/dashboard/platform/articlePushValidRate/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

// 文章推送媒介类型统计
export function articlePushChannelTypeApi(params = {}) {
  let url = `/dashboard/platform/articlePushChannelType`
  return apiServer.get(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 文章推送媒介排名
// export function articlePushApiRankingApi(params = {}) {
//   let url = `/dashboard/platform/articlePushApiRanking`
//   return apiServer.get(url, params).then(res => {
//     return Promise.resolve(res)
//   })
// }
// export function articlePushApiRankingApi(params = {}) {
//   let url = `/dashboard/platform/articleReadNumRanking`
//   return apiServer.get(url, params).then(res => {
//     return Promise.resolve(res)
//   })
// }

// // 文章推送媒介类型统计
// export function articlePushChannelTypeApi(params = {}) {
//   let url = `/dashboard/user/articlePushChannelType`
//   return apiServer.get(url, params).then(res => {
//     return Promise.resolve(res)
//   })
// }
//总阅读数量
export function totalReadNumApi(params = {}) {
  let url = `/dashboard/platform/totalReadNum/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}


export function queryTopDataApi(params = {}) {
  let url = `/data/home/queryTopData/${params}` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function queryReleaseRateApi(params = {}) {
  let url = `/data/home/queryReleaseRate/${params}` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}


export function getValidPushRateApi(params = {}) {
  let url = `/data/home/getValidPushRate/${params}` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function getPushMediumAverageApi(params = {}) {
  let url = `/data/home/getPushMediumAverage/${params}` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}




export function getAllReportNumApi(params = {}) {
  let url = `/data/home/getAllReportNum` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function queryPushChannelTypeApi(params = {}) {
  let url = `/data/home/queryPushChannelType/${params}` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function queryArticleListDataApi(params = {}) {
  let url = `/data/home/queryArticleListData/${params}` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function articlePushApiRankingApi(params = {}) {
  let url = `/data/home/articleReadNumRanking/${params}`
  return apiServer.get(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function getNumByDateApi(params = {}) {
  let url = `/data/home/getNumByDate` //post methods
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}



