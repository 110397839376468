var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "data_statistics" }, [
    _c(
      "div",
      { staticClass: "channelManagement-head" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.$router.push("/home")
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "statistics_detail" },
      [
        _c(
          "el-row",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "border-bottom": "1px solid #ddd",
            },
          },
          [
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "h4",
                { staticStyle: { "font-size": "25px", margin: "20px 0" } },
                [_vm._v(" " + _vm._s(_vm.articleParams.articleTitle) + " ")]
              ),
            ]),
            _c(
              "el-col",
              { staticClass: "text-right", attrs: { span: 12 } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.exportReportClick },
                  },
                  [_vm._v("下载报告")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "statistics_detail" }, [
          _c("div", { staticClass: "statistics_top" }, [
            _c("p", { staticStyle: { color: "#9e9e9e" } }, [
              _vm._v(
                " 分发至今 " +
                  _vm._s(_vm.articleParams.createdTime) +
                  " - " +
                  _vm._s(_vm.articleParams.nowTime) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "data_top_wrap" }, [
              _c("div", { staticClass: "data_top_item" }, [
                _c("h3", [_vm._v(_vm._s(_vm.topParams.pushApiNum))]),
                _c("h3", [_vm._v("分发媒介数")]),
              ]),
              _c("div", { staticClass: "data_top_item" }, [
                _c("h3", [_vm._v(_vm._s(_vm.topParams.channelTypeNum))]),
                _c("h3", [_vm._v("媒介类型")]),
              ]),
              _c("div", { staticClass: "data_top_item" }, [
                !_vm.isEditReadNum
                  ? _c("h3", [_vm._v(_vm._s(_vm.topParams.readNum))])
                  : _vm._e(),
                _c("h3", [
                  _vm._v(" 阅读量"),
                  _vm.userbtn.indexOf("DATASTATISITICE_EDIT_READNUM") >= 0
                    ? _c("i")
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { attrs: { id: "content" } }, [
          _c("div", [
            _c("div", { staticClass: "scope_row_wrap" }, [
              _c("div", { staticClass: "chartDataWarp" }, [
                _c("div", {
                  staticClass: "echarts",
                  staticStyle: { width: "300px", height: "300px" },
                  attrs: { id: _vm.detailId },
                }),
              ]),
              _c(
                "div",
                { staticClass: "chart-list" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        _vm._l(_vm.scopeDetail, function (d, index) {
                          return _c(
                            "div",
                            {
                              key: d.id,
                              staticClass: "chart-list-item",
                              class: "list-item" + index,
                            },
                            [
                              _c("h5", [
                                _c("i", { staticClass: "circle-item" }),
                                _vm._v(" " + _vm._s(d.channelName)),
                              ]),
                              _vm._l(d.list, function (item, ind) {
                                return _c(
                                  "p",
                                  {
                                    key: item.id,
                                    staticStyle: { display: "inline-block" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "20px" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.openUrl(item.urlLink)
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(item.channelName))]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("p", { staticStyle: { "text-align": "left" } }, [
                      _vm._v("同业分发排名:"),
                    ]),
                    _c("div", {
                      staticStyle: { width: "500px", height: "450px" },
                      attrs: { id: "industry" },
                    }),
                    _c("p", { staticStyle: { "text-align": "left" } }, [
                      _vm._v(" 您公司当前在同行业中分发数量排名为第"),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(_vm.rankingNumber)),
                      ]),
                      _vm._v("名! "),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("p", { staticStyle: { "text-align": "left" } }, [
                      _vm._v("同辖区分发排名:"),
                    ]),
                    _c("div", {
                      staticStyle: { width: "500px", height: "450px" },
                      attrs: { id: "bureau" },
                    }),
                    _c("p", { staticStyle: { "text-align": "left" } }, [
                      _vm._v(" 您公司当前在同辖区中分发数量排名为第"),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(_vm.bureauRankingNumber)),
                      ]),
                      _vm._v("名! "),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }